<template>
  <div class="number-card" :class="{'number-card-black':isBlack}">{{text}}</div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  props: ['text'],
  computed: {
    ...mapState(['newTheme']),
    isBlack() {
      return this.newTheme === 'black';
    }
  }
};
</script>

<style lang="less" scoped>
.number-card {
  width: 38px;
  height: 38px;
  line-height: 38px;
  border-radius: 10.89px;
  font-size: 16.34px;
  font-weight: 700;
  background-color: @always-black;
  text-align: center;
  color: @always-white;
  .bold-font();
}
.number-card-black {
  background-color: @always-white;
  color: @always-black;
}
</style>
