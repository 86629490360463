<template>
  <div :class="newTheme">
    <div class="steps-wrap">
        <div class="step-btn"  v-if="!isLogin" @click="goRegister">{{ $t('new_index.adv.reg_now') }}</div>
        <div class="step-btn" @click="goCharge"  v-else>
          {{ $t('school.recharge_btn') }}
        </div>
        <div class="wxt-btn" @click="goWXT">
          <img class="weex-ico" src="~assets/img/new_index/weex_logo_gif.gif" />
          {{ $t('wxtPreIco.buy_wxt') }}
        </div>
    </div>

    <div class="link-wrap">
      <template v-if="locale == 'zh-CN' || locale == 'zh-TW'">
        <div class="step-icon" @click="downloadJumpDl('iosAPP=Y')">
          <img class="icon-img" src="~assets/img/new_index/icon_ios_appstore.svg" />
        </div>
        <div class="step-icon" @click="downloadJumpDl('androidApk=Y')">
          <img class="icon-img" src="~assets/img/new_index/icon_Android_logo.svg" />
        </div>
      </template>
      <template v-if="locale == 'en' || locale == 'ko' || locale === 'vi'">
        <div class="step-icon" @click="go2AppStore()">
          <img class="icon-img" src="~assets/img/new_index/icon_ios_appstore.svg" />
        </div>
        <div class="step-icon" @click="go2GooglePlay()">
          <img class="icon-img" src="~assets/img/new_index/icon_Android_logo.svg" />
        </div>
      </template>
      <div class="step-icon" ref="qrCodeRef" @mouseover="showQR($event)" @mouseleave="hideQR">
        <img class="icon-img" src="~assets/img/new_index/icon_download_qrcode.svg" />
      </div>
      <div class="step-icon"  @click="goOutLink(btokLink)"   v-if="locale === 'zh-CN' || locale === 'zh-TW'">
        <img :src="recommendIco" class="recommend-ico">
        <img class="icon-img" src="~assets/img/new_index/icon_BTok.svg" />
      </div>
      <div class="step-icon" @click="goOutLink($t('common.telegram_link'))" >
        <img class="icon-img" src="~assets/img/new_index/icon_TG.svg" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import { getBtokLink } from '~/config/linkConfig/getBtokLink';
export default {
  data() {
    return {
      regCode: '',
			dropdownShow: false, // 加入社群的下拉菜单是否显示中
      btokLink: '',
    };
  },
  computed: {
    ...mapState(['newTheme', 'locale']),
    ...mapGetters(['isLogin', 'getLang']),
		// 计算推荐图标
		recommendIco() {
			return require(`@/assets/img/new_index/recommend-ico-${this.locale}.svg`);
		}
  },
  mounted() {
    getBtokLink().then(res => {
      this.btokLink = res
    })
  },
  methods: {
    goOutLink(url){
      window.open(url)
    },
    showQR(event) {
      if(this.$refs.qrCodeRef === event.target){
        this.$emit('showQR',event.target);
      }
    },
    // 充币
    goCharge() {
      this.$router.push(this.$i18n.path('asset/recharge'));
    },
    // 跳转WXT页面
    goWXT() {
      this.$router.push(this.$i18n.path('trade/wxt_usdt'))
    },
    hideQR() {
      this.$emit('hideQR');
    },
    // 去注册
    goRegister() {
      if (this.isLogin) {
        this.$router.push(this.$i18n.path('swap'));
        return;
      }
      if (this.regCode) {
        this.$router.push({ path: this.$i18n.path('register'), query: { rname: this.regCode } });
      } else {
        this.$router.push(this.$i18n.path('register'));
      }
    }
  },
  beforeDestroy() {
    this.$children.forEach(child => {
      child.$destroy()
      child = null
    })
  }
};
</script>
<style lang="less" scoped>
.span-width {
  width: 233px
}
.steps-wrap {
  display: flex;
  .steps-input {
    width: 320px;
    ::v-deep .el-input__inner {
      width: 320px;
      height: 48px;
      border-radius: 9px !important;
      border: 1px solid rgba(112, 122, 138, 0.5);
      background: transparent;
      color: #fff;
      // WebKit browsers
      &::-webkit-input-placeholder {
        font-size: 14px;
        color: @gray-P1 !important;
      }
      &:-moz-placeholder {
        font-size: 14px;
        color: @gray-P1 !important;
      }

      // Mozilla Firefox 19+
      &::-moz-placeholder {
        color: @gray-P1 !important;
      }

      // Internet Explorer 10+
      &:-ms-input-placeholder {
        color: @gray-P1 !important;
      }
    }
  }
  .step-btn {
    //margin-left: 10px;

    width: 160px;
    height: 48px;
    background-color: @yellow-P1-new;
    border-radius: 16px;
    color: #000000;
    line-height: 48px;
    font-size: 16px;
    .medium-font();
    text-align: center;
    cursor: pointer;
    &:hover {
      background-color: @yellow-P2-new;
    }
  }

  .wxt-btn{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 8px;
    height: 48px;
    line-height: 48px;
    border-radius: 16px;
    background-color: rgba(255,255,255, 0.1);
    padding-right: 16px;
    font-size: 16px;
    cursor: pointer;
    border: 1px solid transparent;
    box-sizing: border-box;
    .medium-font();

    &:hover{
      border-color: #FFF;
    }

    .weex-ico{
      width: 48px;
      height: 48px;
    }
  }
}
.link-wrap {
  display: flex;
  column-gap: 10px;
  margin-top: 32px;
  .step-icon {
    position: relative;
    width: 44px;
    height: 44px;
    background: rgba(255,255,255, 0.05);
    border-radius: 12px;
    cursor: pointer;
    box-sizing: border-box;
    border: 1px solid transparent;
    &:hover {
      border-color: rgba(255,255,255, 1);
      //background: transparent;
    }
    .icon-img {
      width: 26px;
      height: 26px;
      vertical-align: middle;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }

    .recommend-ico{
      position: absolute;
      top: -9px;
      right: -9px;
      width: 32px;
      height: 16px;
      border-radius: 8px;
      overflow: hidden;
    }
  }
}

.white {
  .link-wrap .step-icon,
  .link-wrap .tg-link-wrap{
    background: #f5f5f6;
    border-color: #f5f5f6;
    &:hover {
      border-color: @yellow-P3;
      background: transparent;
    }
  }
  .steps-wrap .steps-input {
    ::v-deep .el-input__inner {
      color: #000000;
    }
  }
}
</style>
