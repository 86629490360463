<template>
  <div class="popular-activity-button" :class="[classname]">
    {{ $t('startPage.oneContent.btn') }}
  </div>
</template>

<script>
export default {
  props: ['classname']
};
</script>

<style lang="less" scoped>
div,
section {
  box-sizing: border-box;
}
.popular-activity-button {
  width: 166px;
  height: 40px;
  border-radius: 12px;
  background-color: @black-btn-secondary-default;
  font-size: 14px;
  font-weight: 400;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  &:hover {
    background-color: @black-btn-secondary-hover;
  }
}
</style>