<template>
  <div class="home-swiper-box-h5">
    <div v-swiper:mySwiper="swiperOption" @click-slide="clickSlide" class="swiperWrap">
      <div class="swiper-wrapper">
        <div v-for="(item, index) in bannerImg" :key="index" class="swiper-slide">
          <img :src="item.imgUrl" alt />
        </div>
      </div>
      <div v-if="bannerImg.length>1" class="swiper-pagination swiper-pagination-bullets" style="text-align: center; bottom: 0"></div>
    </div>
  </div>
</template>

<script>
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseApp } from '~/plugins/firebase.js';
import { callAppMethod, compareVersions } from "~/utils/utils.js"
// Initialize Firebase
// const analytics = getAnalytics(firebaseApp);
export default {
  data() {
    return {
      idx: 0,
      swiperOption: {
        loop: this.bannerImg.length > 1,
        autoplay: {
          delay: 3000, // 圖片停留時間3秒
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        speed: 500, //輪播速度
        pagination: {
          el: ".swiper-pagination",
          type: "custom",
          renderCustom: function (swiper, current, total) {
            // 这里因为 this 作用域的关系，不能直接使用 this 获取 Vue 相关内容，通过上面的 const vm = this，使用 vm 获取
            let _html = "";
            for (let i = 1; i <= total; i++) {
              if (current == i) {
                _html += `<div style="width:0.9rem;height:0.4rem;border-radius:0.2rem;background-color:#E6AD00;margin-left:0.4rem;margin-right:0.4rem"></div>`;
              } else {
                _html += `<div style="width:0.4rem;height:0.4rem;border-radius:0.4rem;background-color:rgba(112,122,138,0.2);margin-left:0.4rem;margin-right:0.4rem"></div>`;
              }
            }
            return (
              '<div style="justify-content:center;width:100%;align-items:center;display:flex;">' +
              _html +
              "</div>"
            );
          }
        },
        on: {
          slideChange() {
            if (window.__updateSlideIndex) {
              window.__updateSlideIndex(this.realIndex)
            }
          },
        }
      },
      analytics: {}
    };
  },
  props: {
    bannerImg: {
      type: Array,
      default: () => []
    },
  },
  mounted() {
    this.analytics = getAnalytics(firebaseApp);
    window.__updateSlideIndex = (idx) => {
      this.$emit('updateSlideIndex', idx)
    }
  },
  beforeDestroy() {
    window.__updateSlideIndex = null
  },

  methods: {
    /**
    * @method 跳转移动端页面(android、ios、webView)
    * @param {String} mobilePage 移动端跳转的页面名称
    */
    gotoMobilePath(mobilePage) {
      const isFlag = compareVersions(this.appInfo.appVersion, '3.3.0');
      // 新版本页面对应jsCallAPP方法参数映射
      // page目前支持：login、asset、coin，如有其他页面请添加
      const newAppMethodMaping = {
        // 登录
        login: {
          func: 'gotoLogin',
        },
      };
      // 老版本跳转页面对应方法与参数映射
      // page目前支持：login、asset、coin，如有其他页面请添加
      const oldAppMethodMaping = {
        // 登录
        login: {
          method: 'goLogin',
          param: 'login'
        },
        // 现货交易
        spot: {
          method: 'goRootController',
          param: 'coin'
        },
        // 资产
        asset: {
          method: 'goRootController',
          param: 'asset'
        }
      };
      const params = isFlag
        ? JSON.stringify(newAppMethodMaping[mobilePage])
        : oldAppMethodMaping[mobilePage].param;
      // 新版本使用jsCallAPP+参数、老版本使用老版本跳转页面对应方法与参数映射中的方法
      const method = isFlag ? 'jsCallAPP' : oldAppMethodMaping[mobilePage].method;
      // 跳转app内页面
      callAppMethod(method, params);
    },
    /**
     * 点击banner业务处理
     * @param index
     * @param reallyIndex 下标数
     */
    clickSlide(index, reallyIndex) {
      let _this = this;
      const idx = reallyIndex ?? index
      const current = this.bannerImg[idx]
      if (!current) {
        return
      }
      const { jumpLink, jumpType, mainTitle } = current

      // 跳app
      if (jumpType === 1) {
        this.gotoMobilePath(jumpLink)
        return
      }

      if (jumpLink.indexOf('topic/agent-introduction-topic') != -1) {
        _this.goto('topic/agent-introduction-topic', mainTitle);
      } else if (jumpLink.indexOf('competition?fromAPP=Y') != -1) {
        _this.goto('competition?fromAPP=Y', mainTitle);
      } else if (jumpLink.indexOf('competition') != -1) {
        _this.goto('competition', mainTitle);
      } else if (this.isHttpOrHttps(jumpLink)) {
        _this.openLink(jumpLink, mainTitle);
      } else {
        // 如果是 /home 需要处理成 home
        const pathUrl = jumpLink.startsWith('/') ? jumpLink.slice(1) : jumpLink;
        _this.goto(pathUrl, mainTitle)
      }

    },
    isHttpOrHttps(url) {
      try {
        new URL(url);
        return true;
      } catch (err) {
        return false;
      }
    },
    goto(path, title) {
      //logEvent(this.analytics, '轮播图-'+title);
      logEvent(this.analytics, 'banner_event', {
        content_type: title,
        platform: "h5"
      });
      this.$router.push(this.$i18n.path(path));
    },
    openLink(hrefStr, title) {
      //logEvent(this.analytics, '轮播图-'+title);
      logEvent(this.analytics, 'banner_event', {
        content_type: title,
        platform: "h5"
      });
      window.open(hrefStr);
    },

  }
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  overflow-y: auto;
}

.home-swiper-box-h5 {
  position: relative;
  margin-bottom: 32px;

  .swiperWrap {
    padding-bottom: 15px !important;

    .swiper-slide {
      width: 100%;
      text-align: center;
      img {
        max-width: 100%;
      }
    }
  }
}
</style>