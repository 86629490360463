import { render, staticRenderFns } from "./WXDialog.vue?vue&type=template&id=bedd0bae&scoped=true"
import script from "./WXDialog.vue?vue&type=script&lang=js"
export * from "./WXDialog.vue?vue&type=script&lang=js"
import style0 from "./WXDialog.vue?vue&type=style&index=0&id=bedd0bae&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bedd0bae",
  null
  
)

export default component.exports