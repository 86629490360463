<template>
  <div class="thumb-example">
    <div class="active-index" v-if="showImageList.length > 1">
      <el-progress
        type="circle"
        :width="20"
        :percentage="process"
        :format="timer"
        color="#34300B"
        :stroke-width="2"
      ></el-progress>
      <span class="active-index-text">{{activeIndex+1}}/{{showImageList.length}}</span>
    </div>
    <!-- <img
      v-if="showImageList.length > 1"
      src="~/assets/img/ad/icon-bg-close.svg"
      alt
      class="btn-top-right-close"
      @click="onCloseCurrentImg"
    /> -->
    <!-- swiper1 -->
    <VueSlickCarousel
      v-if="showImageList.length>0"
      ref="swiperTop"
      @afterChange="onSlideChange"
      class="swiper-wrapper gallery-top"
      height="750"
      :arrows="false"
      :autoplaySpeed="3000"
      :infinite="true"
      :autoplay="false"
      :slidesToShow="1"
      :slidesToScroll="1"
    >
      <div v-for="item in showImageList" :key="item.id" class="top-slide">
        <img :src="item.picUrl" @click="openNewWindow(item.forward)" class="gallery-img" />
      </div>
    </VueSlickCarousel>
    <!-- swiper2 Thumbs -->
    <VueSlickCarousel
      v-if="showImageList.length > 1"
      ref="swiperThumbs"
      class="swiper-wrapper gallery-thumbs"
      height="96"
      :arrows="false"
      :centerMode="false"
      :infinite="false"
      :autoplay="false"
      :focusOnSelect="false"
      :slidesToShow="1"
      :slidesToScroll="1"
      centerPadding="50px"
      :variableWidth="true"
    >
      <div
        v-for="(item,index) in showImageList"
        :key="item.id"
        class="thumbs-img-row"
        @click="onThumbsSlideChange(index)"
      >
        <section class="thumbs-img-opa" v-if="activeIndex!==index"></section>
        <img :src="item.picUrl" class="gallery-thumbs-img" />
      </div>
    </VueSlickCarousel>
    <div class="learn-more-row" :class="{'flex-between':showCountdown}">
      <date-countdown
        v-if="showCountdown"
        :countdownTime="countdownTime"
        :currentTime="currentTime"
        @callback="onUpdateCurrentTime($event)"
      />
      <section
        class="btn-learn-more"
        @click="openNewWindow(currentUrl)"
      >{{$t("ad_dialog.learn_more")}}</section>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import 'swiper/dist/css/swiper.css';
import moment from 'moment';
import DateCountdown from './DateCountdown/DateCountdown.vue';
import { isClient } from '~/utils/envHelper.js';

const hiddenFloatIndexsKey = 'weex:hidden-float-win';
const hiddenFloatIndexsKeyNoLogin = 'weex:hidden-float-win-no-login';
const todayDate = moment().format('YYYY-MM-DD');

export default {
  name: 'wx-swiper',
  props: {
    imgs: []
  },
  components: {
    DateCountdown
  },
  data() {
    return {
      freshTime: 0,
      timerid: 0,
      process: 100, // 倒计时变量
      activeIndex: 0,
      currentTime: Date.now(), // 服务器时间
      countdownTime: 0, // 广告是否有倒计时
      showImageList: [],
      currentUrl: '', // 当前广告 url
      carouselTop: 0, // 走马灯当前索引
      carouselThumbs: 0,
      carouselThumbsPage: 0,
      curTimerid: 0
    };
  },
  computed: {
    ...mapGetters(['isLogin', 'loginName']),
    showCountdown() {
      return !!this.countdownTime; //&& this.countdownTime > this.currentTime;
    }
  },
  methods: {
    // 打开新窗口
    openNewWindow(url) {
      var newWin = window.open();
      newWin.opener = null;
      newWin.location = url;
      newWin.target = '_blank';
    },
    // 倒计时计算
    timer(percentage) {
      return `${Math.ceil(percentage / 33.3333)}s`;
    },
    // 滚动倒计时
    countdown() {
      this.timerid && clearTimeout(this.timerid);
      this.timerid = setTimeout(() => {
        if (this.process <= 0) {
          if (this.$refs.swiperTop) {
            this.$refs.swiperTop.next();
          }
          return;
        }
        this.process = this.process - 4 < 0 ? 0 : this.process - 4;
        this.countdown();
      }, 120);
    },
    onSlideChange(newIndex) {
      this.timerid && clearTimeout(this.timerid);
      const imgsLen = this.showImageList.length;
      if (imgsLen <= 1) {
        return;
      }
      const slideIndex = newIndex % 4;
      if (slideIndex === 0) {
        this.$refs.swiperThumbs.goTo(newIndex);
      }
      this.process = 99;
      if (imgsLen > 0) {
        this.activeIndex = newIndex;
        this.currentUrl = this.showImageList[newIndex]?.forward;
        this.countdownTime = this.showImageList[newIndex]?.countdownTime || 0;
        this.currentTime = this.showImageList[newIndex]?.currentTime;

        setTimeout(() => {
          this.countdown();
        }, 200);
      }
    },
    onThumbsSlideChange(index) {
      this.$refs.swiperTop.goTo(index);
      const slideIndex = index % 4;
      if (slideIndex === 0) {
        this.$refs.swiperThumbs.goTo(index);
      }
    },
    // 计算可显示的图片
    showImgs(imgs) {
      let result = imgs;
      if (isClient) {
        const hiddenIndexsObj = this.getCurrentHiddenObj();
        const todayObj = hiddenIndexsObj[todayDate];

        if (hiddenIndexsObj && todayObj) {
          // 如果登录，则剔除本人设置隐藏的广告
          if (this.isLogin) {
            // @note 获取当前人员的设置
            const curUserObj = todayObj.find(item => item.loginName === this.loginName);
            if (curUserObj) {
              result = imgs.filter(item => !(curUserObj.indexs || []).includes(item.id));
            }
          } else {
            const hiddenIndexs = todayObj || [];
            result = imgs.filter(item => !hiddenIndexs.includes(item.id));
          }
        }
      }

      return result;
    },
    getCurrentHiddenObj() {
      const hiddenStr = localStorage.getItem(this.isLogin ? hiddenFloatIndexsKey : hiddenFloatIndexsKeyNoLogin);
      return JSON.parse(hiddenStr) || {};
    },
    onCloseCurrentImg() {
      const resetCurrentImg = () => {
        this.showImageList = this.showImgs(this.imgs);
        this.$nextTick(() => {
          const preIndex = this.activeIndex;
          const curIndex = preIndex < 0 ? 0 : preIndex >= this.showImageList.length ? preIndex - 1 : preIndex;
          this.currentUrl = this.showImageList[curIndex].forward || '';
          this.currentTime = this.showImageList[curIndex].currentTime;
          this.countdownTime = this.showImageList[curIndex].countdownTime || 0;
        });
      };
      const showImgList = this.showImageList;
      // 登录状态
      if (this.isLogin) {
        const firstSet = () => {
          // @note 更改 userid
          localStorage.setItem(
            hiddenFloatIndexsKey,
            `{"${todayDate}":[{"loginName":"${this.loginName}","indexs":["${showImgList[this.activeIndex].id}"]}]}`
          );

          resetCurrentImg();
        };
        const currentHiddenObj = this.getCurrentHiddenObj();
        if (currentHiddenObj) {
          const todayObj = currentHiddenObj[todayDate];
          // 设置前先判断当前日期是否设置过，也有可能被别人设置
          if (todayObj) {
            // @note 更改 userid
            let currentUserHiddenObj = todayObj.find(item => item.loginName === this.loginName);
            // 如果当前用户存在
            if (currentUserHiddenObj) {
              currentUserHiddenObj.indexs.push(showImgList[this.activeIndex].id);
            } else {
              // @note 更改 userid
              todayObj.push({
                loginName: this.loginName,
                indexs: [showImgList[this.activeIndex].id]
              });
            }
            localStorage.setItem(hiddenFloatIndexsKey, JSON.stringify(currentHiddenObj));
            resetCurrentImg();
          } else {
            // 当前的不存在，则删除所有
            localStorage.removeItem(hiddenFloatIndexsKey);
            firstSet();
          }
        } else {
          firstSet();
        }
      } else {
        const currentHiddenObj = this.getCurrentHiddenObj();
        // 如果已设置过，对象已存在
        if (currentHiddenObj[todayDate]) {
          const hiddenIndexs = currentHiddenObj[todayDate] || [];
          hiddenIndexs.push(showImgList[this.activeIndex].id);
          localStorage.setItem(hiddenFloatIndexsKeyNoLogin, `{"${todayDate}":${JSON.stringify(hiddenIndexs)}}`);

          resetCurrentImg();
        } else {
          localStorage.removeItem(hiddenFloatIndexsKeyNoLogin);
          localStorage.setItem(hiddenFloatIndexsKeyNoLogin, `{"${todayDate}":["${showImgList[this.activeIndex].id}"]}`);
          resetCurrentImg();
        }
      }

      if (this.showImageList.length === 0) {
        this.$emit('swiper-callback', false);
      }
    },
    initShowImageList(imgs) {
      this.showImageList = this.showImgs(imgs);
      this.onUpdateCurrentTime();
      this.$nextTick(() => {
        if (this.showImageList.length > 0) {
          this.currentUrl = this.showImageList[0].forward || '';
          this.currentTime = this.showImageList[0].currentTime;
          this.countdownTime = this.showImageList[0].countdownTime || 0;
        }
        if (this.process > 0 && this.showImageList.length > 1) {
          this.countdown();
        }
      });
    },
    onUpdateCurrentTime() {
      this.curTimerid && clearTimeout(this.curTimerid);
      this.curTimerid = setTimeout(() => {
        this.showImageList
          .filter(item => item.currentTime > 0)
          .forEach(item => {
            item.currentTime = item.currentTime + 1000;
          });
        this.onUpdateCurrentTime();
      }, 1000);
    }
  },
  watch: {
    imgs(newVal, oldValue) {
      if (newVal !== oldValue) {
        this.initShowImageList(newVal);
      }
    }
  },
  mounted() {
    // 清除非当日的设置
    const currentHiddenObj = this.getCurrentHiddenObj();
    if (currentHiddenObj && !currentHiddenObj[todayDate]) {
      if (this.isLogin) {
        localStorage.removeItem(hiddenFloatIndexsKey);
      } else {
        localStorage.removeItem(hiddenFloatIndexsKeyNoLogin);
      }
    }

    if (this.process > 0 && this.showImageList.length > 1) {
      this.countdown();
    }
  },
  destroyed() {
    clearTimeout(this.timerid);
    clearTimeout(this.curTimerid);
  }
};
</script>

<style lang="less" scoped>
.thumb-example {
  width: 652px;
  position: relative;
  .active-index {
    box-sizing: border-box;
    position: absolute;
    left: 16px;
    top: 16px;
    z-index: 100;
    background-color: rgba(255, 255, 255, 0.3);
    padding: 0 4px;
    width: 54px;
    height: 24px;
    border-radius: 16px;
    display: flex;
    align-items: center;
    .active-index-text {
      font-size: 12px;
      font-weight: 500;
      .medium-font();
      color: @black-text-secondary;
      display: block;
      margin-left: 5px;
    }
    .circle-count-down {
      width: 20px;
      height: 20px;
    }
  }
  .btn-top-right-close {
    position: absolute;
    right: 24px;
    top: 16px;
    width: 20px;
    height: 20px;
    z-index: 100;
    cursor: pointer;
  }
  .learn-more-row {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 16px;
    .btn-learn-more {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #ffe82d;
      .medium-font();
      font-size: 14px;
      font-weight: 500;
      text-align: center;
      width: 200px;
      height: 48px;
      border-radius: 16px;
      cursor: pointer;
      color: #000;
      &:hover {
        background-color: @btn-primary-hover;
      }
    }
  }
  .flex-between {
    justify-content: space-between;
  }
}

.swiper-wrapper {
  overflow: hidden;
  .top-slide {
    height: 400px;
    width: 652px;
    border-radius: 16px;
    overflow: hidden;
  }
  .gallery-img {
    cursor: pointer;
    height: 400px;
    width: 652px;
    border-radius: 16px;
  }
  .thumbs-slide {
    width: 143px;
    height: 88px;
  }
  .gallery-thumbs-img {
    cursor: pointer;
    width: 143px;
    height: 88px;
    border-radius: 12px;
  }
  &.gallery-top {
    height: 400px;
    width: 652px;
    margin-bottom: 16px;
  }
  &.gallery-thumbs {
    display: flex;
    height: 88px;
    box-sizing: border-box;

    .thumbs-img-active {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      opacity: 1;
    }
  }
  .thumbs-img-row {
    cursor: pointer;
  }
}
</style>
<style lang="less">
.el-progress__text {
  font-size: 10px !important;
  font-weight: 400;
  color: #fff;
}
.gallery-top {
  .slick-list {
    border-radius: 16px !important;
    .slick-track {
      height: 400px;
    }
  }
}
.gallery-thumbs {
  .slick-slide {
    position: relative;
    .thumbs-img-opa {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 143px;
      height: 88px;
      border-radius: 12px;
      background-color: rgba(0, 0, 0, 0.4);
    }
  }
  .slick-slide + .slick-slide {
    margin-left: 12px;
  }
  .slick-active {
    // opacity: 1;
  }
}
</style>
