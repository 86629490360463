<template>
  <div
    class="float-activity-container"
    :class="{'float-activity-hover': isHover}"
    v-if="img"
    @click="onClick"
    @mouseenter="onMouseEnter"
    @mouseleave="onMouseLeave"
  >
    <img alt :src="img" class="float-img" @load="onLoadImg" :class="{'floatHoverImg': isHover}" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { getFloatActivity } from '~/server/activity';

export default {
  name: 'FloatActivity',
  data() {
    return {
      img: '',
      blackBigImg: '',
      blackLittleImg: '',
      whiteBigImg: '',
      whiteLittleImg: '',
      url: '',
      isHover: false,
      showImg: false
    };
  },
  methods: {
    onClick() {
      var newWin = window.open();
      newWin.opener = null;
      newWin.location = this.url;
      newWin.target = '_blank';
    },
    onMouseEnter() {
      this.showImg = true;
      if (this.newTheme === 'black') {
        this.img = this.blackBigImg;
      } else {
        this.img = this.whiteBigImg;
      }
    },
    onMouseLeave() {
      this.showImg = false;
      this.isHover = false;
      if (this.newTheme === 'black') {
        this.img = this.blackLittleImg;
      } else {
        this.img = this.whiteLittleImg;
      }
    },
    onLoadImg() {
      if (this.showImg) {
        this.isHover = true;
      }
    },
    init() {
      let lang = this.locale;
      switch (this.locale) {
        case 'zh-CN':
          lang = 'cn';
          break;
        case 'zh-TW':
          lang = 'tw';
          break;
      }
      getFloatActivity({ language: lang }).then(res => {
        if (res.code === '00000' && res.data) {
          const imgInfoArr = res.data.floatingWindowI18n;
          const imgInfo = imgInfoArr.length > 0 ? imgInfoArr[0] : null;
          if (imgInfo) {
            this.url = imgInfo.url;
            this.img = imgInfo.nightModeWebBannerUrl;
            this.blackBigImg = imgInfo.nightBigModeWebBannerUrl;
            this.blackLittleImg = imgInfo.nightModeWebBannerUrl;
            this.whiteBigImg = imgInfo.daytimeBigModeWebBannerUrl;
            this.whiteLittleImg = imgInfo.daytimeModeWebBannerUrl;
          }
        }
      });
    }
  },
  computed: {
    ...mapState(['newTheme', 'locale'])
  },
  watch: {
    locale() {
      this.init();
    },
    newTheme(val) {
      if (val === 'black') {
        this.img = this.blackLittleImg;
      } else {
        this.img = this.whiteLittleImg;
      }
    }
  },
  mounted() {
    this.init();
  }
};
</script>

<style lang="less" scoped>
.float-activity-container {
  position: fixed;
  right: 24px;
  bottom: 108px;
  cursor: pointer;
  width: 104px;
  height: 104px;
  z-index: 100000;
  .float-img {
    width: 104px;
    height: 104px;
  }
}
.float-activity-hover {
  width: 326px;
  cursor: pointer;
  transition: width 1s ease-in-out;
  .float-img {
    width: 326px;
    height: 104px;
  }
}
</style>