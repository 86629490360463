<template>
  <div>
    <WXDialog :visible="adDialogVisible&&imgs.length>0">
      <div class="float-ad-container">
         <img
          src="~/assets/img/ad/icon-close.svg"
          alt
          v-ui-test="'close-ad-dialog'"
          class="btn-top-right-close"
          @click="onCloseSwiper"
        />
        <section class="content-container" :class="{'content-container-black':isBlack}">
          <WXSwiper :imgs="imgs" @swiper-callback="swiperCallback" />
        </section>
        <section class="no-reminders-row">
          <WXCheckBoxVue :isChecked.sync="noReminderToday"></WXCheckBoxVue>
          <span class="no-reminders-text">{{$t("ad_dialog.no_more_reminder")}}</span>
        </section>
        <!-- <section class="btn-close-row">
          <img src="~/assets/img/ad/icon-close.svg" class="btn-close" alt @click="onCloseSwiper" />
        </section> -->
      </div>
    </WXDialog>
  </div>
</template>

<script>
import moment from 'moment';
import { mapState, mapGetters } from 'vuex';
import WXDialog from '../WXDialog.vue';
import WXSwiper from './WXSwiper.vue';
import { getPopUpData } from '../../server/common/index.js';
import WXCheckBoxVue from '../WXCheckBox.vue';
import { isClient } from '~/utils/envHelper.js';
import { getLangCodeByLang } from '~/utils/locale';

const floatNoMindersTodayKey = 'floatNoMindersTodayKey';
const floatNoMindersTodayKeyNoLogin = 'floatNoMindersTodayKeyNoLogin';

const hiddenFloatIndexsKey = 'weex:hidden-float-win';
const hiddenFloatIndexsKeyNoLogin = 'weex:hidden-float-win-no-login';
const todayDate = moment().format('YYYY-MM-DD');

export default {
	name: 'AdDialog',
  components: {
    WXDialog,
    WXSwiper,
    WXCheckBoxVue
  },
  data() {
    return {
      dialogVisible: false,
      adDialogVisible: false,
      noReminderToday: false, //今天不再提示
      allImages: [], // 从服务端获取的所有图片
      imgs: []
    };
  },
  computed: {
    ...mapState(['newTheme', 'locale']),
    ...mapGetters(['isLogin', 'loginName', 'getLang']),
    isBlack() {
      return this.newTheme === 'black';
    }
  },
  methods: {
    onCloseSwiper() {
      if (this.noReminderToday) {
        if (this.isLogin) {
          // @note 修改 userid
          localStorage.setItem(floatNoMindersTodayKey, `{"${todayDate}":["${this.loginName}"]}`);
        } else {
          localStorage.setItem(floatNoMindersTodayKeyNoLogin, `true`);
        }
      }
      this.adDialogVisible = false;
    },
    getCurrentHiddenObj() {
      return JSON.parse(localStorage.getItem(this.isLogin ? hiddenFloatIndexsKey : hiddenFloatIndexsKeyNoLogin)) || {};
    },
    getImgs(imgs) {
      let result = imgs;
      if (isClient) {
        const hiddenIndexsObj = this.getCurrentHiddenObj();
        const todayObj = hiddenIndexsObj[todayDate];
        if (hiddenIndexsObj && todayObj) {
          // 如果登录，则剔除本人设置隐藏的广告
          if (this.isLogin) {
            // @note 获取当前人员的设置
            const curUserObj = todayObj.find(item => item.loginName === this.loginName);
            if (curUserObj) {
              result = imgs.filter(item => !(curUserObj.indexs || []).includes(item.id));
            }
          } else {
            const hiddenIndexs = todayObj || [];
            result = imgs.filter(item => !hiddenIndexs.includes(item.id));
          }
        }
      }

      return [...result];
    },
    setAdDialog() {
      // 取出需要显示在首页的
      let list = this.allImages.filter(tmp => tmp.position == '1');
      // 取出非禁用状态的
      list = list.filter(tmp => tmp.status == 'ENABLE');

      if (this.isLogin) {
        // 登录状态先排除非登录弹框
        list = list.filter(tmp => tmp.loginStatus != '3');
      } else {
        // 非登录状态先排除登录弹框
        list = list.filter(tmp => tmp.loginStatus != '2');
      }
      /**
       * 请注意此处的语系档code 和 其他的模块返回的code不一样
       * TODO 等待后端修改后，前后端对齐 https://jira.weex.tech/browse/WWIT-12591
       */
      const lan = {
        'zh-CN': '1',
        'zh-TW': '2',
        'en': '3',
        'ko': '4',
        'ja': '5',
        'vi': '6'
      }

      const lanCode = getLangCodeByLang(this.getLang) ?? 0 // 英文兜底
      list = list.filter(tmp => tmp.languageType == lanCode)
                 .filter(item => item.picUrl);
      // 过滤语系
      // if (this.getLang == 'zh-CN') {
      //   list = list.filter(tmp => tmp.languageType == '1');
      // } else if (this.getLang == 'zh-TW') {
      //   list = list.filter(tmp => tmp.languageType == '2');
      // } else if (this.getLang == 'en') {
      //   list = list.filter(tmp => tmp.languageType == '3');
      // } else if (this.getLang == 'ko') {
      //   list = list.filter(tmp => tmp.languageType == '4');
      // } else if (this.getLang == 'ja') {
      //   list = list.filter(tmp => tmp.languageType == '5');
      // } else if (this.getLang == 'vi') {
      //   list = list.filter(tmp => tmp.languageType == '6');
      // } else {
      //   //英文兜底
      //   list = list.filter(tmp => tmp.languageType == '3');
      // }
      // list = list.filter(item => item.picUrl);

      this.imgs = this.getImgs(list);
      if (this.isLogin) {
        const floatNoMinderTodayObj = JSON.parse(localStorage.getItem(floatNoMindersTodayKey)) || {};
        if (floatNoMinderTodayObj[todayDate]) {
          // @note 修改 userid
          this.adDialogVisible = !floatNoMinderTodayObj[todayDate].includes(this.loginName);
        } else {
          this.adDialogVisible = true;
          localStorage.removeItem(floatNoMindersTodayKey);
        }
      } else {
        this.adDialogVisible =
          !localStorage.getItem(floatNoMindersTodayKeyNoLogin) ||
          !localStorage.getItem(floatNoMindersTodayKeyNoLogin) === 'true';
      }
    },
    swiperCallback(visible) {
      this.adDialogVisible = visible;
    }
  },
  watch: {
    isLogin() {
      this.setAdDialog();
    },
    getLang() {
      this.setAdDialog();
    }
  },
  mounted() {
    // @todo 获取弹窗数据
    getPopUpData().then(res => {
      if (res.code == '00000' && res.data.length > 0) {
        this.allImages = res.data;
        this.setAdDialog();
      } else if (res.code != '00000') {
        BT.util.info(res.msg || 'error...');
        console.log('getPopUpData error');
      }
    });
  }
};
</script>

<style lang="less" scoped>
div,
section {
  box-sizing: border-box;
}
.float-ad-container {
  // width: 798px;
  // height: 796px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  box-sizing: border-box;
  position:relative;
  .btn-top-right-close {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 24px;
    height: 24px;
    z-index: 100;
    cursor: pointer;
  }
  .content-container {
    border-radius: 16px;
    padding: 16px;
    background-color: @white-bg-primary;
    margin-bottom: 16px;
  }
  .content-container-black {
    background-color: @black-bg-primary;
  }
  .no-reminders-row {
    display: flex;
    align-items: center;
    justify-content: center;

    .no-reminders-text {
      box-sizing: border-box;
      margin-left: 8px;
      font-size: 14px;
      font-weight: 400;
      color: @black-text-primary;
    }
  }
  .btn-close-row {
    display: flex;
    align-items: center;
    justify-content: center;
    .btn-close {
      width: 32px;
      height: 32px;
      cursor: pointer;
    }
  }
}
</style>
