<template>
  <div class="dialog-download-app" :class="newTheme" v-if="showDia">
    <div class="mod" :class="{ show: showMod }">
      <div class="content">
        <p class="title">{{ $t("newFooter.download") }} WEEX APP</p>
        <p class="sub-tit">{{ $t("index.download_h2") }}</p>
        <div class="qrcode">
          <download-qr class="content-qr" :lang="locale" :size="100"></download-qr>
        </div>
      </div>
      <div class="btn" @click="closeDialog">{{ $t("activity.msg.opt_btn4") }}</div>
      <img class="close-btn" src="~/assets/img/common/gray-close.png" @click="closeDialog" />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
  components: {
    'download-qr': () => import('~/components/common/DownloadQr.vue'),
  },
  data() {
    return {
      locale: "",
      showDia: false,
      showMod: false
    };
  },
  computed: {
    ...mapState(['newTheme']),
  },
  methods: {
    showDialog() {
      this.showDia = true;
      setTimeout(_ => {
        this.showMod = true;
      }, 10);
    },
    closeDialog() {
      document.body.style.overflow = "scroll"; //關閉彈窗恢復scroll
      this.showMod = false;
      setTimeout(_ => {
        this.showDia = false;
      }, 600);
    }
  },
  mounted() {
    this.locale = this.$i18n.locale;
  }
};
</script>

<style lang="less" scoped="scoped">
@media screen and (max-width: 768px) {

  .dialog-download-app {
    color: @text-white;
    position: fixed;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(15, 17, 21, 0.5);
    z-index: 9999999;
    display: flex;

    .mod {
      background: @black-white-mask;
      padding: 3.8rem 3rem 3rem;
      border-radius: 1 * 0.5rem;
      margin-top: 10 * 0.5rem;
      transform: translateY(-50 * 0.5rem);
      opacity: 0;
      transition: all 0.6s ease-in-out;
      position: relative;

      &.show {
        transform: translateY(0);
        opacity: 1;
      }

      .close-btn {
        position: absolute;
        width: 1.4rem;
        height: 1.4rem;
        font-size: 2.8 * 0.5rem;
        top: 1.5rem;
        right: 1.5rem;
        cursor: pointer;
        line-height: 1;
      }

      .content {
        padding: 0 5 * 0.5rem;
        text-align: center;

        .title {
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 2rem;
          margin-bottom: 0.6rem;
        }

        .sub-tit {
          font-size: 2.4rem;
          font-weight: 500;
          line-height: 3.3rem;
        }

        img {
          margin-bottom: 0.8 * 0.5rem;
        }

        .qrcode {
          width: 120px;
          height: 120px;
          margin: 2.4rem auto;
          border-radius: 0.3rem;
          background: white;
          transition: all 0.35s;

          .content-qr {
            display: block;
            padding: 1rem;
          }
        }
      }

      .btn {
        height: 4.2rem;
        line-height: 4.2rem;
        text-align: center;
        background: @yellow-P1;
        .pxToVw375H5(border-radius, 12);
        margin-top: 3.6rem;
        color: @text-black;
        font-size: 1.4rem;
        .medium-font();
      }
    }
  }

  .white {
    &.dialog-download-app {
      color: #000;

      .mod {
        background: @white-mask;
      }
    }
  }
}
</style>