<template>
  <span
    class="check-box"
    :class="[{active: checked, 'is-disabled': disabled},`${theme}-check-box`]"
    @click="setChecked"
  >
    <img
      v-if="checked"
      :src="theme === 'black' ? iconRightBlack : iconRightWhite"
      alt
      class="ck-box-right"
    />
  </span>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: ['isChecked', 'disabled'],
  data() {
    return {
      checked: this.isChecked,
      iconRightBlack: require('~/assets/img/ad/ck-right-black.svg'),
      iconRightWhite: require('~/assets/img/ad/ck-right-white.svg')
    };
  },
  computed: {
    ...mapState({
      theme: state => state.theme
    })
  },
  methods: {
    setChecked() {
      if (this.disabled) return;
      this.checked = !this.checked;
      this.$emit('update:isChecked', this.checked);
    }
  },
  watch: {
    isChecked(val) {
      this.checked = val;
    }
  }
};
</script>

<style lang="less">
.check-box {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  width: 14px;
  height: 14px;
  border-radius: 3px;
  border: 1px solid @white-border-strong;
  cursor: pointer;
  z-index: 1;
  &:hover {
    border: 1px solid #fff;
  }
  &.active {
    background-color: @white-text-primary;
    border: none;
  }
  .ck-box-right {
    width: 9px;
    height: 7px;
  }

  &.is-disabled {
    cursor: not-allowed;
    /* &.active {
        background-color: #e1e3e5;
        border-color: #e1e3e5;
      } */
  }
}
.black-check-box {
  &.active {
    background-color: @black-text-primary;
    border: none;
  }
}
</style>
