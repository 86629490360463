<template>
  <!-- 中文和繁体语言下轮播切换 -->
  <section v-if="showBanner" class="zh-carousel" style="position: relative" :class="[theme,`theme-${theme} home-box`]"
       @mouseenter="autoplay = false"
       @mouseleave="autoplay = true"
  >
    <img src="/baseasset/banner.png" fetchpriority="high" style="width: 100%;height: 100%; position: absolute;">
    <div class="zh-main-wrap" :class="locale + curIndex">
      <div v-if="showCarousel" class="left-arrow" @click="arrowClick('left')">
        <template>
          <img class="icon-img arrow-default" :src="`/assets/images/new_index/banner/icon_L_hui${darkTheme? '': '_white'}.png`" alt="" />
          <img class="icon-img arrow-default-hover" :src="`/assets/images/new_index/banner/icon_L_active${darkTheme? '': '_white'}.png`" alt="" />
        </template>
      </div>
      <el-carousel ref="cardShow" :indicator-position="showIndicator" arrow="never" :interval="6000" :autoplay="autoplay" height="736px"
        @change="(curIndex, prevIndex) => {
            this.curIndex = curIndex;
          }
          ">
        <el-carousel-item v-for="(banner, index) in bannerList" :key="`carousel-item-${index+1}}`">
          <div class="item-wrap" :style="banner.ext?.homeAdvantage ? 'padding-top: 42px' : ''" :key="`carousel-item-${index}`"  @click="bannerClick(banner.title, index+1)">
            <div class="img-box">
              <img class="banner-img" :src="banner.theme.dark.MainImgPath" :key="`carousel-img-${index+1}`" alt="" />
            </div>
            <section class="banner-text-row">
              <div class="left-wrap">
                <template>
                  <h1 class="banner-title" v-html="banner.title"></h1>
                  <div class="banner-desc" v-html="banner.description"></div>
                  <a target="_blank" v-if="banner.ext?.type === 'linkButton'" :class="banner.ext?.linkClass" :href="banner.ext.link"> {{ banner.ext.linkLabel }}</a>
                </template>
                <div ref="linkRef">
                  <BannerLink @showQR="showQR" @hideQR="hideQR"></BannerLink>
                </div>
                <div v-if="banner.ext?.homeAdvantage" class="home-advantage-new" :class="{ en: ['en', 'vi'].includes(locale) }">
                  <div class="item" v-for="(item, index) in banner.ext?.homeAdvantage" :key="`adv-${index+1}`">
                    <img class="img" :src="item.iconImgPath" alt />
                    <div class="title">{{ $t(item.titleKey) }}</div>
                    <div class="sub-tit">{{ $t(item.subTitleKey) }}</div>
                  </div>
                </div>
              </div>
            </section>
            <a v-if="banner.MainImgLink"
               class="right-main-wrap"
               @click="gotoByUrl(banner.MainImgLink)"
               title=""></a>
            
          </div>
        </el-carousel-item>
      </el-carousel>
      <div v-if="showCarousel" class="right-arrow" @click="arrowClick('right')">
        <template>
          <img class="icon-img arrow-default" :src="`/assets/images/new_index/banner/icon_R_hui${darkTheme? '': '_white'}.png`" alt="" />
          <img class="icon-img arrow-default-hover" :src="`/assets/images/new_index/banner/icon_R_active${darkTheme? '': '_white'}.png`" alt="" />
        </template>
      </div>
      <!-- 下载二维码 -->
      <!-- 因轮播框父级的overflow：hidden会截取二维码，故提取到外层-->
      <div class="qr download" :class="locale" ref="qrCodeRef" @mouseover="showQR($event.target,true)" @mouseleave="hideQR">
        <div class="img-container">
          <div class="qr-code">
            <download-qr style="position: relative; top: 6px;left: 6px" :lang="locale" :size="100"> </download-qr>
          </div>
          <!-- <div class="logo-qr">
            <img src="~/assets/img/new_index/logo-qr.svg" />
          </div> -->
        </div>
        <div class="tit">{{ $t('new_index.header.download_app_tit2') }}</div>
        <div class="more-download" @click="goPath('download')">
          {{ $t('new_index.header.download_more') }}
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import BannerLink from './BannerLink.vue';
import {PC_BannerClick} from "../../utils/sensorsEvent";
import config from "~/config/bannerConfig.json";
import {
  getMainBanner
} from '../../server/common/index.js';
export default {
  props: ['theme'],
  components: {
    BannerLink,
    'download-qr': () => import('~/components/common/DownloadQr.vue')
  },
  data() {
    return {
      qrLeft:0,
      qrTop:0,
      qrOverSelf:false,
      qrTimer:null,
      regCode: '',
      curIndex: 0,
      autoplay: true,
      showCarousel: false,
      darkTheme: this.theme === 'black',
      bannerList:[],
      showBanner: true,
      showIndicator: 'none', // 輪播下方切換是否展示
      lanList: [
        {
            "key": "en",
            "num": 0,
        },
        {
            "key": "ko",
            "num": 3,
        },
        {
            "key": "zh-TW",
            "num": 5,
        },
        {
            "key": "zh-CN",
            "num": 1,
        },
        {
            "key": "vi",
            "num": 4,
        },
      ]
    };
  },
  computed: {
    ...mapState(['locale']),
    ...mapGetters(['isLogin', 'getLang']),


  },
  mounted() {
    this.getBannerContent();
    
  },
  methods: {
    setBannerInfo(data) {
        this.showCarousel = data?.carousel;
        this.showCarousel ? this.showIndicator = '': this.showIndicator = 'none';
        this.bannerList =  data?.banners
        this.showBanner = data?.banners?.length > 0
    },
    /**
     * 获取首页banner信息
     */
    getBannerContent() {
      const lanInfo = this.lanList.find(item => item.key === this.$i18n.locale)

      getMainBanner()
        .then(res => {
          // console.log(res)
          if (res.code === '00000') {
            const {mainBannerList = []} = res?.data ?? {}
            const showBannerList = mainBannerList
              .filter(item => item.languageId === lanInfo?.num)
              .filter(item => item.status === 1)
              .filter(item => item.removalTime ? item.removalTime>new Date().getTime() : true)
            // console.log(showBannerList.length, 1111111111)
            if (showBannerList.length) {
              // const result = current
              const result = {
                locale: "en",
                carousel: showBannerList.length > 1,
                banners: showBannerList?.map((item, idx) => {
                  return {
                    "id": idx + 1 + '',
                    "title": item.mainTitle ?? '',
                    "description": item.subtitleTitle ?? '',
                    "ext": {
                      "type": "tgButton",
                      "link": "https://t.me/WeexGlobal_Group",
                      "linkTitle": "",
                      "linkLabel": "Join WEEX community, get bonus"
                    },
                    "MainImgLink": item.webJumpLink,
                    "theme": {
                      "light": {
                        "MainImgPath": item.webPosterWhiteUrl
                      },
                      "dark": {
                        "MainImgPath": item.webPosterDarkUrl
                      }
                    },
                    "enable": item.status === 1,
                    "removalTime":item.endTime
                   }
                })
              }
              this.setBannerInfo(result)
              return
              // console.log(123, result)
            }

            // 如果没哟可用的配置信息则使用默认配置
            const defaultBannerInfo = config.find(item=>item.locale === this.$i18n.locale)
            defaultBannerInfo.banners = defaultBannerInfo.banners.filter(item=>{
              // 判断轮播enable是否显示和是否已经下架。
              return item.enable&&new Date(item.removalTime).getTime()>new Date().getTime()
            })
            defaultBannerInfo.carousel = defaultBannerInfo.banners?.length > 1
            this.setBannerInfo(defaultBannerInfo)
          } else {
            if (res.msg) {
              BT.util.info(res.msg);
            }
          }

        })
        .catch(() => {
          //500或者请求超时 对swiper 都进行初始化
          //this.swiperInit();
        });
    },
    gotoByUrl(url) {
      if (this.isHttpOrHttps(url)){
        window.open(url);
      } else {
        // 如果是 /home 需要处理成 home
        const pathUrl = url.startsWith('/') ? url.slice(1) : url;
        this.goPath(pathUrl)
      }
    },
    isHttpOrHttps(url) {
      try {
        new URL(url);
        return true;
      } catch (err) {
        return false;
      }
    },
    bannerClick(banner_name, banner_position) {
			PC_BannerClick(banner_name, banner_position)
    },
    showQR(target,isSelf) {
      const domEle = this.$refs.qrCodeRef;
      this.qrOverSelf = isSelf;
      clearTimeout(this.qrTimer);
      if(isSelf){
          domEle.style.left = this.qrLeft;
          domEle.style.top = this.qrTop;
        }else{
          if(target && target.getBoundingClientRect){
          const rect = target.getBoundingClientRect();
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
          const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
          const clientTop = document.documentElement.clientTop || 0;
          const clientLeft = document.documentElement.clientLeft || 0;
          const left =rect.left + scrollLeft - clientLeft + 45;
          const top = rect.top + scrollTop - clientTop - 330;
          this.qrLeft = left + 'px';
          this.qrTop = top + 'px';
          
          domEle.style.left = this.qrLeft;
          domEle.style.top = this.qrTop;
         }
        }
      domEle.style.display = 'block';
      
    },
    hideQR() {
      this.qrTimer = setTimeout(() => {
        this.$refs.qrCodeRef.style.display = 'none';
      }, 300);
    },
    arrowClick(val) {
      if (val === 'right') {
        this.$refs.cardShow.next();
      } else {
        this.$refs.cardShow.prev();
      }
    },
    goPath(path) {
      this.$router.push(this.$i18n.path(path));
    }
  },
  beforeDestroy() {
    this.$children.forEach(child => {
      child.$destroy()
      child = null
    })
    this.$refs.cardShow.$el.innerHTML = '';
    this.$refs.cardShow.$destroy()
    this.$refs.cardShow = null
  }
};
</script>

<style lang="less" scoped>
img[src=''],
img:not([src]) {
  opacity: 0;
}

.zh-carousel {
  // height: 736px;
  .zh-main-wrap {
    min-width: 1200px;
    max-width: 1920px;

    // max-height: 736px;
    margin: auto;
    position: relative;

    .qr {
      position: absolute;
      left: 275px;
      top:340px;
      z-index: 10;
      color: @text-black;
      display: none;
      background: @black-popup-bg-new;
      transform: translateY(60px);
      border-radius: 12px;
      box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.12);
      padding: 19px 34px;

      // 下载
      &.ko {
        //transform: translateY(43px);
        padding: 19px 17px;
      }

      &.download {
        box-sizing: border-box;
        //border-radius: 9px;

        .tit {
          font-size: 14px;
          .medium-font();
          font-weight: 500;
          color: @text-black;
          line-height: 18px;
          text-align: center;
          margin-top: 10px;
          margin-bottom: 13px;
          color: #fff;
        }

        .qr-code {
          width: 112px;
          height: 112px;
          background-color: #fff;
          transition: all 0.35s;
          border-radius: 10px;

          >img {
            position: relative;
            width: 22px;
            height: 22px;
          }
        }

        .img-container {
          width: 112px;
          height: 112px;
          margin: 16px auto;
        }

        .more-download {
          min-width: 84px;
          padding: 8px 12px;
          background: @yellow-P5;
          color: @yellow-P3;
          font-size: 14px;
          line-height: 20px;
          border-radius: 8px;
          text-align: center;
          margin: 0 auto;
          cursor: pointer;
        }

        .logo-qr {
          width: 22px;
          height: 22px;
          position: relative;
          top: -67px;
          left: 45px;
        }
      }
    }


    .left-arrow,
    .right-arrow {
      position: absolute;
      top: 348px;
      width: 40px;
      height: 40px;
      cursor: pointer;
      z-index: 10;

      .icon-img {
        width: 40px;
        height: 40px;
      }

      .arrow-default-hover {
        display: none;
      }

      &:hover {
        .arrow-default {
          display: none;
        }

        .arrow-default-hover {
          display: block;
        }
      }
    }

    .left-arrow {
      left: -170px;
    }

    .right-arrow {
      right: -170px;
    }

    .item-wrap {
      // display: flex;
      // align-items: center;
      // justify-content: flex-start;
      position: relative;
      height: 736px;

      .img-box{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        .banner-img{
          position: absolute;
          width: 100%;
          height: auto;
          right: 0;
          top: 50%;
          transform: translateY(-50%);
        }
        
      }

      .banner-text-row{
        width: 1200px;
        position: relative;
        // transform: translateY(50%);
        margin-left: auto;
        margin-right: auto;
        display: flex;
        height: 736px;
      }

      .left-wrap {
        width: 600px;
        position: relative;
        z-index: 999;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        .banner-title {
          font-size: 48px;
          font-weight: bold;
          line-height: 56px;
          .bold-font();
          margin-bottom: 32px;
          color: #fff;
          &.orange {
            color: @yellow-P3;
          }
        }

        .banner-desc {
          font-size: 24px;
          line-height: 26px;
          font-weight: 400;
          margin-bottom: 72px;
          color: @black-text-secondary;
          &.green {
            font-weight: 700;
            color: @green-color;
          }
        }

        .join-activity {
          display: inline-block;
          margin-bottom: 16px;
          font-size: 16px;
          color: @gray-P1;
          height: 22px;
          max-width: 200px;
          align-items: center;
          padding-right: 20px;
          background-image: url(~assets/img/common/icon_jiantou.svg);
          background-position: right center;
          background-repeat: no-repeat;
          background-size: 16px 16px;

          &:hover {
            color: @yellow-P2-new;
            background-image: url(~assets/img/common/icon_jiantou_hover.svg);
          }
        }

        .home-advantage-new {
          .flex();
          margin-top: 60px;
          margin-bottom: 67px;

          .item {
            width: 150px;

            .img {
              width: 30px;
              height: 30px;
            }

            .title {
              margin-top: 12px;
              font-size: 14px;
              color: @gray-P1;
              line-height: 14px;
            }

            .sub-tit {
              width: 100px;
              margin-top: 7px;
              font-size: 12px;
              color: @gray-P1;
              line-height: 14px;
              white-space: pre-line;
            }
          }

          &.en {
            margin-top: 40px;

            .item {
              width: 200px;
            }

            .sub-tit {
              width: 150px;
            }
          }
        }
      }

      .right-main-wrap {
        cursor: pointer;
        position: relative;
        z-index: 10000;
        position: absolute;
        right:0;
        top: 0;
        width: 50%;
        display: block;
        //right: -80px;
        // flex: 1;
        //width: 713px;
        height: 736px;


        .img-box {
          width: 713px;
        }
      }
    }
  }

  ::v-deep .el-carousel {
    .el-carousel__indicators {
      bottom: 17px;
    }

    .el-carousel__indicator {
      padding: 0 4px;

      .el-carousel__button {
        width: 8px;
        height: 8px;
        background: @black-input-color;
        border-radius: 4px;
      }

      &.is-active {
        .el-carousel__button {
          width: 20px;
          background: @yellow-P3;
        }
      }
    }
  }
}

.white {
  &.zh-carousel {
    .zh-main-wrap {

      .left-wrap .banner-title,
      .qr.download .tit,
      .left-wrap .banner-desc,
      .left-wrap .join-activity {
        color: #000000;
      }
    }

    ::v-deep.el-carousel {
      .el-carousel__indicator {
        .el-carousel__button {
          background: @white-input-color;
        }

        &.is-active {
          .el-carousel__button {
            width: 20px;
            background: @yellow-P3;
          }
        }
      }
    }

    .zh-main-wrap .qr {
      background: @white-popup-bg;
    }
  }
}

@media screen and (max-width: 1600px) {
  .zh-carousel .zh-main-wrap .left-arrow {
    left: -60px;
  }

  .zh-carousel .zh-main-wrap .right-arrow {
    right: -60px;
  }
}

@media screen and (max-width: 1200px) {
  .zh-carousel .zh-main-wrap .left-arrow {
    left: 20px;
  }

  .zh-carousel .zh-main-wrap .right-arrow {
    right: 20px;
  }
}

@media screen and (min-width: 1600px) {
  .zh-carousel .zh-main-wrap .left-arrow {
    left: -170px;
  }

  .zh-carousel .zh-main-wrap .right-arrow {
    right: -170px;
  }
}

@media screen and (max-width:768px) {
  .zh-carousel .zh-main-wrap .item-wrap .left-wrap{
    width: 380px;
    margin: -40px 0 0 10px;
  }
}
</style>

