<template>
  <div>
    <div :class="newTheme == 'black' ? 'home' : 'home home_white'">
      <div class="introduce">
        <div class="introduce_cont">
          <div v-if="mainBannerImgList.length" class="introduce_top">
            <div>
              <MainBanner @updateSlideIndex="idx => this.slideIndex = idx" :bannerImg="mainBannerImgList" />
            </div>
          </div>
          <div v-if="showDefaultBanner" class="introduce_top">
            <img :src="bgImg" />
          </div>
          <div class="introduce_bottom">
            <div v-if="mainBannerImgList.length" class="title">
              <div v-html="mainTitle"></div>
              <p class="title2" v-html="subTitle"></p>
            </div>
            <div v-if="showDefaultBanner" class="title">
              <!-- <div>{{ $t("new_index.adv.title1") }}</div> -->
              <div v-if="locale == 'zh-CN' || locale == 'zh-TW'">{{ $t("new_index.adv.title1") }}</div>
              <div v-if="locale == 'en' || locale == 'ko' || locale == 'vi'">{{ $t("new_index.adv.title1") }}</div>
              <!-- <div v-if="locale=='en'||locale=='ko'||locale=='vi'">{{ $t("link.get_reward") }}</div> -->
              <p class="title2">{{ $t("new_index.adv.title2") }}</p>
            </div>
            <div v-if="showDefaultBanner && (locale == 'zh-CN' || locale == 'zh-TW')" class="sub-tit">
              <p>{{ $t("new_index.adv.sub_title_wu") }}</p>
            </div>
            <div class="input">
              <input v-model="registerName" type="text" @blur="onValidEmail"
                :placeholder="$t('new_index.adv.email_phone')" />
            </div>
            <div v-if="visibleEmailError" class="email-error">{{ registerErr }}</div>
            <div class="login_register_box">
              <div class="login_btn" @click="goToDownload()">
                {{ $t("common.login") }}
              </div>
              <div class="newRegister_btn" @click="gotoRegister(registerName)">
                {{ $t("register.btn_reg") }}
              </div>

            </div>

          </div>
        </div>
      </div>
      <div class="quickDownload">
        <div class="title">
          <div class="line" :class="{ 'ko': locale == 'ko' }"></div>
          <div class="sub-tit">{{ $t("newFooter.download") }}</div>
          <div class="line" :class="{ 'ko': locale == 'ko' }"></div>
        </div>
        <template v-if="locale == 'zh-CN' || locale == 'zh-TW'">
          <div class="download">
            <div class="btn" @click="goToDownload('iosAPP=Y')">
              <img class="icon" src="~/assets/img/common/icon_IOS.png" />
              <p>{{ $t("spokesperson.app_download.btn1") }}</p>
            </div>
            <div class="btn" @click="goToDownload('androidApk=Y')">
              <img class="icon" src="~/assets/img/common/icon_Android.png" />
              <p>{{ $t("spokesperson.app_download.btn4") }}</p>
            </div>
          </div>
        </template>
        <template v-else>
          <div class="download">
            <div class="btn" @click="go2AppStore()">
              <img class="icon" src="~assets/img/new_index/icon_appstore.png">
              <p>App Store</p>
            </div>
            <div class="btn" @click="go2GooglePlay()">
              <img class="icon" src="~assets/img/new_index/icon_Google-Play.png">
              <p>Google Play</p>
            </div>
          </div>
        </template>
      </div>
      <div style="padding-bottom: 15px;">
        <div v-if="banner_img.length != 0" class="swiper">
          <SwiperH5 v-if="showBanner" :bannerImg="banner_img" :bannerLink="banner_link" :bannerTitle="banner_title" />
        </div>
        <div class="notice" v-if="noticeList.length > 0">
          <img v-if="newTheme == 'black'" src="~assets/img/new_index/icon_laba.png" />
          <img v-if="newTheme == 'white'" src="~assets/img/new_index/icon_laba_white.png" />
          <div class="marquee_box">
            <ul class="marquee_list" :class="{ marquee_top: noticeAnimate }">
              <li v-for="(item, index) in noticeList">
                <a class="title" :title="item.title" :href="item.noticeUrl">{{ item.title }}</a>
              </li>
            </ul>
          </div>
          <div class="more" @click="noticeMore">
            <img src="~/assets/img/new_index/icon_more.svg" />
          </div>
        </div>
      </div>
      <div class="market" v-if="contractMarketList.length">
        <div class="list_head">
          <div class="src_org">
            <h2 class="current_price">{{ $t("new_index.table.market_trend") }}</h2>
            <div class="more-market-item" @click="showDownloadApp()">
              {{ $t("index.notice_more") }}
              <div class="icon-more">
                <img src="~/assets/img/new_index/icon_more.svg" />
              </div>
            </div>
          </div>
          <div class="coin-tab">
            <div>
              <h3 :class="{ active: indexMarketTab === 0 }" @click="indexMarketTab = 0; getBitExchange(0)">
                {{ $t("new_index.table.contract") }}
              </h3>
              <div v-if="indexMarketTab === 0" class="switch" />
            </div>
            <div style="width:2rem;" />
            <div>
              <h3 :class="{ active: indexMarketTab === 1 }" @click="indexMarketTab = 1; getBitExchange(1)">
                {{ $t("new_index.table.increase_list") }}
              </h3>
              <div v-if="indexMarketTab === 1" class="switch" />
            </div>
            <div style="width:2rem;" />
            <div>
              <h3 :class="{ active: indexMarketTab === 2 }" @click="indexMarketTab = 2; getBitExchange(2)">
                {{ $t("new_index.table.trading_list") }}
              </h3>
              <div v-if="indexMarketTab === 2" class="switch" />
            </div>
          </div>
          <div class="list_title">
            <div class="coinId">{{ $t("new_index.table.name") }}</div>
            <div class="price">{{ $t("new_index.table.price") }}</div>
            <div class="change_24h">{{ $t("new_index.table.rise_fall_24h") }}</div>
          </div>
        </div>
        <div class="list_content">
          <div v-for="(item, index) in contractMarketList" :key="index">
            <div class="list_data">
              <nuxt-link class="list_coinId" :to="goSwap(item)"> 
                {{ item.left }}/{{ item.right }}
              </nuxt-link>
              <div v-if="item.price != '---'" class="list_price">
                ₮{{ item.price }}
              </div>
              <div v-else class="list_price">{{ item.price }}</div>
              <div class="list_change_24h" :class="{ price_green: item.float > 0, price_red: item.float < 0 }">
                {{ item.floatText }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="officia-wrap">
        <div class="officia-img"></div>
        <div class="officia-item">
          <div class="title">1,500,000,000+</div>
          <div class="txt">{{ $t('new_index.official-data.cont1.sub-tit') }}</div>
        </div>
        <div class="officia-item">
          <div class="title">1,000,000+</div>
          <div class="txt">{{ $t('new_index.official-data.cont2.sub-tit') }}</div>
        </div>
        <a class="officia-item"
          rel="nofollow"
          href="https://explorer.btc.com/zh-CN/btc/address/bc1qufzpe776gv25v3ejv0a8vk07vhqpelql9jl0cv">
          <div class="title">{{ $t('new_index.official-data.cont3.tit') }}</div>
          <div class="txt right-arrow-wrap">{{ $t('new_index.official-data.cont3.sub-tit') }}</div>
        </a>
      </div>
      <div class="school" v-if="locale == 'zh-CN' || locale == 'zh-TW'">
        <div class="school-des">
          <h2 class="title">{{ $t("new_index.weex_course") }}</h2>
          <div v-if="locale != 'en'" class="more-school-item" @click="goto('school')">
            {{ $t("index.notice_more") }}
            <div class="icon-more">
              <img src="~/assets/img/new_index/icon_more.svg" />
            </div>
          </div>
        </div>
        <VideoSwiper />
      </div>
      <div class="downloadApp" ref="appDownloadH5">
        <h2 class="app_download_title">{{ $t("index.download_h2") }}</h2>
        <div class="app_downlaod_img">
          <img class="downloadImg" :src="downloadImg" alt />
        </div>
        <div class="app_download_link">
          <div class="qrcode">
            <div class="qrcode-bg">
              <div class="download-qrcode-show-content">
                <download-qr :lang="locale" :size="80"></download-qr>
              </div>
            </div>
            <div>
              <p class="title">{{ $t("common.download_app") }}</p>
              <p class="sub-tit">{{ $t("common.qrcode2download") }}</p>
            </div>
          </div>
          <div class="app_download_icon" :class="newTheme === 'black' ? 'theme-black down_app' : 'down_app'">
            <div class="icon_top">
              <div class="ios_app app-item" @click="goToDownload('iosAPP=Y')">
                <div class="icon" />
                <p>iOS app</p>
              </div>
              <div class="android_app app-item" @click="goToDownload('androidApk=Y')">
                <div class="icon" />
                <p>Android APK</p>
              </div>
            </div>
            <div class="icon_bottom">
              <div class="ios_app2 app-item" @click="go2AppStore()">
                <div class="icon" />
                <p>App Store</p>
              </div>
              <div class="android_app2 app-item" @click="go2GooglePlay()">
                <div class="icon" />
                <p>Google Play</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="joint-venture">
        <div v-for="item in ventureList" class="joint-item"
          :style="`background-image: url(${newTheme == 'white' ? item.white_img : item.black_img});`"
          :key="item.img_name">
        </div>
      </div>
    </div>
    <DialogDownloadApp ref="dialog"></DialogDownloadApp>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { ACTION_TYPES } from "~/store/newTrading/types";
import { throttle, cloneDeep } from 'lodash';
import { Decimal } from "decimal.js";
import { fixedDisplay } from "~/utils/utils.js";
import { topBanner, getMainBanner } from "~/server/common/index.js";
import SwiperH5 from "~/components/indexH5/Banner.vue";
import MainBanner from "~/components/indexH5/MainBanner.vue";
import { subscribeTickerAll, unSubscribeTickerAll } from '~/src/newWebsocket2/tickerAll';
import DialogDownloadApp from "~/components/indexH5/Dialog_download_app.vue";
import VideoSwiper from '~/components/indexH5/videoSwiper.vue';
import { getContractActiveRank } from "~/server/common";

export default {
  components: {
    SwiperH5,
    MainBanner,
    VideoSwiper,
    DialogDownloadApp,
    'download-qr': () => import('~/components/common/DownloadQr.vue'),
    jointVenture: () => import('~/components/index/jointVenture'),
  },
  data() {
    return {
      locale: "zh-CN",
      appDownloadShow: true,
      achorClose: false,
      hostname: "",
      slideIndex: 0,
      list: [
        {
          productName: "BTC/USDT",
          baseSymbol: "BTC",
          name: "Bitcoin",
          price: "---",
          change_24h: "0",
          high24h: "---",
          low24h: "---",
          vol24h: "---",
        },
        {
          productName: "ETH/USDT",
          baseSymbol: "ETH",
          name: "Ethereum",
          price: "---",
          change_24h: "0",
          high24h: "---",
          low24h: "---",
          vol24h: "---",
        },
        {
          productName: "LTC/USDT",
          baseSymbol: "LTC",
          name: "Litecoin",
          price: "---",
          change_24h: "0",
          high24h: "---",
          low24h: "---",
          vol24h: "---",
        },
        {
          productName: "DOGE/USDT",
          baseSymbol: "DOGE",
          name: "Polkadot",
          price: "---",
          change_24h: "0",
          high24h: "---",
          low24h: "---",
          vol24h: "---",
        },
        {
          productName: "TRX/USDT",
          baseSymbol: "TRX",
          name: "Ripple",
          price: "---",
          change_24h: "0",
          high24h: "---",
          low24h: "---",
          vol24h: "---",
        },
      ],
      noticeAnimate: false,
      noticeList: [],
      deviceType: "iOS", //iOS or android
      displayNewBanner: true, //代言人banner,
      banner_img: [],
      banner_link: [],
      banner_title: [],
      // 主轮播图片列表
      mainBannerImgList: [],
      // 是否展示默认banner
      showDefaultBanner: false,
      showBanner: false,
      interval: null,
      languageType: "",
      registerName: "",
      indexMarketTab: 0, // 新的首页市场tab：0-自选，1-合约（正反向放在一起），2-专业合约，3-混合合约，4-币币
      contractMarketList: [], // 合约
      coinListFold: true, // 是否折叠
      intervalID: null,
      menuShow: false,
      visibleEmailError: false,
      registerErr: '',
      lanList: [
        {
          "key": "en",
          "num": 0,
        },
        {
          "key": "ko",
          "num": 3,
        },
        {
          "key": "zh-TW",
          "num": 5,
        },
        {
          "key": "zh-CN",
          "num": 1,
        },
        {
          "key": "vi",
          "num": 4,
        },
      ],
      displayQuoteList: [],
      quoteList: [],
      topFutureIdList: [], // 熱門合約
      top5List: [], // 前五名熱門榜
    };
  },
  mounted() {
    hideGlobalLoading();
    this.getContractRank(); // 首次進入首頁立即获取热门合约数据
    this.handlePageLoad();
    this.locale = this.$i18n.locale;
    this.getLang(this.locale);
    //如果頁面來自合夥人計畫跳轉過來要直接锚點到下載區塊
    if (location.href.indexOf("fromPartnerPlan") != -1) {
      window.innerWidth >= 768
        ? this.scrollToAnchor("appDownloadPC")
        : this.scrollToAnchor("appDownloadH5");
    }
    this.hostname = location.hostname;
    this.getDeviceType();
    this.getBannerContent();
    this.getBannerNotice();
    this.getBitExchange(this.indexMarketTab);

    if (location.href.indexOf('fromFooterAppDownload=Y') != -1) {
      setTimeout(_ => {
        this.goDownload();
      }, 200);
    }
  },
  beforeDestroy() {
    window.onresize = null;
    if (this.interval) clearInterval(this.interval); // 离开时销毁
    clearInterval(this.intervalID);
    // 取消订阅
    // unSubscribeTickerAll();
  },
  methods: {
    ...mapActions('newTrading', { fetchMetadata: ACTION_TYPES.FETCH_CONTRACT_LIST }),
    goSwap(item,type = 1){
      return this.$i18n.path((type === 1 ? 'swapnew/' : 'delivery/') + item.productCode)
    },
    goToDownload(type){
      let path = 'support/jumpdl';
      if(type){
        path = `${path}?${type}`
      }
      this.$router.push(this.$i18n.path(path));
    },
    // 獲取熱門合約榜
    getContractRank() {
      getContractActiveRank().then(data => {
        if (data.code === '00000') {
          this.topFutureIdList = data.data.slice(0, 5);
          this.top5List = this.getTop5List(this.topFutureIdList);
        }
      }).catch(() => {
        //BT.util.error(this.$t('trade.operation.orderFail'))
      });
    },
    getTop5List(list) {
      const filteredList = [];
      list.forEach(contractId => { // 自选列表按添加自选时间倒序排列
        const findSelect = this.contractList.find(element => Number(element.contractId) === contractId);
        findSelect && filteredList.push(findSelect.baseSymbol);
      });
      return filteredList;
    },
    handlePageLoad() {
      // 获取行情数据
      this.fetchMetadata().then(() => {
        // subscribeTickerAll();
      });
    },
    getQuoteList() {
      try {
        // 获取行情数据
        let list = this.tickers;
        // 要移到最前
        const coinsToMoveToFront = this.top5List;
        let frontList = [];
        // 将要移到最前面的币种逐一处理
        coinsToMoveToFront.forEach(coin => {
          if (list.length > 0) {
            // 查找币种在列表中的索引
            const index = list.findIndex(item => item?.productName?.split('/')[0] === coin);
            if (index > -1) {
              const item = list.splice(index, 1)[0];
              frontList.push(item);
            }
          }
        });
        // 将币种移动到列表最前面
        if (frontList.length > 0) {
          list = frontList.concat(list);
        }
        // 返回处理后的列表
        return list;
      } catch (error) {
        console.error('Error fetching quotes:', error);
        return []; // 如果出现错误，返回一个空数组
      }
    },
    goto(path) {
      this.$router.push(this.$i18n.path(path));
    },
    /**
     * 滚动播放 公告信息
     */
    showMarquee: function () {
      this.noticeAnimate = true;
      setTimeout(() => {
        this.noticeList.push(this.noticeList[0]);
        this.noticeList.shift();
        this.noticeAnimate = false;
      }, 500);
    },
    /**
     * 查询公告信息
     */
    getBannerNotice() {
      topBanner().then(res => {
        if (res && res.data && res.msg === "success") {
          this.noticeList = res.data.noticeList || [];
          if (this.noticeList.length > 0) setInterval(this.showMarquee, 3000);
          let _this = this;
          res.data.bannerList.forEach(function (item, index, array) {
            _this.$set(_this.banner_img, index, item.imgUrl);
            _this.banner_link[index] = item.jumpUrl;
            _this.banner_title[index] = item.title;
          });
          this.showBanner = true;
        }
      });
    },
    /**
    * 获取首页banner信息
    */
    getBannerContent() {
      const lanInfo = this.lanList.find(item => item.key === this.locale) || {}
      this.showDefaultBanner = false
      getMainBanner()
        .then((res = {}) => {
          if (res.code === '00000' && res.data) {
            const { mainBannerList = [] } = res.data
            const showBannerList = mainBannerList
              .filter(item => item.languageId === lanInfo.num)
              .filter(item => item.status === 1)
              .filter(item => item.endTime > new Date().getTime() || !item.endTime)
              .map(item => {
                const postUrl = this.newTheme === 'black' ? item.h5PosterDarkUrl : item.h5PosterWhiteUrl
                return {
                  imgUrl: postUrl,
                  jumpLink: item.h5JumpLink,
                  jumpType: item.jumpType,
                  mainTitle: item.mainTitle,
                  subTitle: item.subtitleTitle
                }
              })
            if (showBannerList.length > 0) {
              this.mainBannerImgList = showBannerList
            } else {
              this.showDefaultBanner = true
            }
          }

        })

    },
    toCurrency(num) {
      var parts = num.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      return parts.join(".");
    },
    closeAnchor() {
      this.achorClose = true;
    },
    scrollToAnchor(refName) {
      var element = this.$refs[refName];
      element.scrollIntoView({ behavior: "smooth" });
    },
    go2AppStore() {
      let href =
        "https://apps.apple.com/us/app/weex%E5%94%AF%E5%AE%A2-%E6%95%B0%E5%AD%97%E8%B4%A7%E5%B8%81%E5%90%88%E7%BA%A6%E4%BA%A4%E6%98%93%E5%B9%B3%E5%8F%B0-btc-eth/id1609350789";
      window.open(href);
    },
    go2GooglePlay() {
      let href =
        "https://play.google.com/store/apps/details?id=com.wake.weexprd";
      window.open(href);
    },
    showDownloadApp() {
      document.body.style.overflow = "hidden"; //開啟彈窗禁止scroll
      this.$refs.dialog.showDialog();
    },
    noticeDetail(url) {
      window.location.href = url;
    },
    noticeMore() {
      window.open(this.$t("notice.notice_link"));
    },
    newRegMod() {
      document.body.style.overflow = "hidden"; //開啟彈窗禁止scroll
      this.$refs.dialog.showDialog();
    },
    getDeviceType() {
      if (/(iPhone|iPad|iPod|iOS|Mac)/i.test(navigator.userAgent)) {
        console.log("iOS Device");
        this.deviceType = "iOS";
      } else {
        this.deviceType = "android";
      }
    },
    getLang(locale) {
      switch (locale) {
        case "zh-CN": {
          this.languageType = 1;
          break;
        }
        case "zh-TW": {
          this.languageType = 5;
          break;
        }
        case "en": {
          this.languageType = 0;
          break;
        }
        case "ko": {
          this.languageType = 3;
          break;
        }
        default: {
          break;
        }
      }
    },

    displayMarket(list) {
      if (list && list.length > 0) {
        const finalList = list.map((item, index) => {
          const { productName, markPrice, priceChangePercent, value } = item ?? {};
          const product = productName?.split('/') || [];
          const coinSymbol = product[0]?.toUpperCase();
          const priceSymbol = product[1]?.toUpperCase();
          const tmpItem = {
            // 合约数据
            float: +Number((priceChangePercent || 0)),
            floatText: fixedDisplay((Number(priceChangePercent || 0) * 100), 2, Decimal.ROUND_FLOOR, '%'),
            left: coinSymbol,
            price: markPrice,
            productCode: `cmt_${productName?.replace('/', '')?.toLowerCase()}`,
            right: priceSymbol,
            trade: value
          }
          return tmpItem;
        });
        this.contractMarketList = finalList; // 僅取前五筆
      }
    },
    //, 获取币种信息
    getBitExchange(tabIndex) {
      if(this.top5List.length <= 0) return;
      const list = cloneDeep(this.quoteList);
      switch (tabIndex) {
        case 1:
          list.sort((a, b) => b.priceChangePercent - a.priceChangePercent);
          break;
        case 2:
          list.sort((a, b) => b.value - a.value);
          break;
      }

      if (list.length > 0) {
        const displayQuoteList = list?.splice(0, 5);
        this.displayQuoteList = displayQuoteList;
        this.displayMarket(displayQuoteList);
      }
      if (!isNaN(tabIndex) && (this.indexMarketTab !== tabIndex)) {
        this.indexMarketTab = tabIndex;
      }
    },
    goDownload() {
      document.querySelector(".downloadApp").scrollIntoView();
    },
    menuStatus(val) {
      this.menuShow = val;
    },
    // @note 邮箱有大写进行提示
    onValidEmail() {
      this.visibleEmailError = false;
      if (this.registerName && this.registerName.indexOf('@') > -1) {
        if (/[A-Z]+/.test(this.registerName)) {
          // @note 如果有大写字母则提示区分大小写
          this.registerErr = this.$i18n.t('new_index.adv.email_hint_letter');
          this.visibleEmailError = true
        }

      }
    },
    gotoRegister(rname) {
      let query = {};
      if (rname !== "" && rname !== undefined) {
        query.rname = rname
      }
      this.$router.push({
        path: this.$i18n.path('register'),
        query
      })
    }
  },
  computed: {
    ...mapState({
      contractList: state => state.newTrading.contractList,
      newAllTicker: state => state.newTrading.newAllTicker,
    }),
    ventureList() {
      const list = ['yahoo', 'investing', 'Binance', 'TradingView', 'TheTimes', 'KingData', 'ForesightNews', 'Bloomberg', 'Coinepeaker', 'BTCcom', 'jinsecaijing', 'ChipPay', 'CoinGecko', 'Coinsph', 'Tscientific', 'feixiaohao'];
      return list.map(item => {
        return {
          img_name: item,
          default_img: require(`~/assets/img/index/jointVentureIcon/${item}_hui.svg`),
          white_img: require(`~/assets/img/index/jointVentureIcon/${item}_white.svg`),
          black_img: require(`~/assets/img/index/jointVentureIcon/${item}_black.svg`)
        };
      });
    },
    tickers() {
      const tickerData = this.newAllTicker || window.$nuxt.$store.state.newTrading.newAllTicker;
      let tickers = [];
      // 排除模擬盤
      const contracts = this.contractList?.filter(item => +item.quoteCoinId === 2);
      if (contracts?.length > 0 && tickerData) {
        contracts.forEach(contract => {
          const ticker = tickerData[contract.contractId];
          if (ticker) {
            ticker.productName = contract.productName;
            tickers.push(ticker);
          }
        });
      }
      return tickers;
    },
    mainTitle() {
      if (!this.mainBannerImgList[this.slideIndex]) {
        return ''
      }
      return this.mainBannerImgList[this.slideIndex].mainTitle
    },
    subTitle() {
      if (!this.mainBannerImgList[this.slideIndex]) {
        return ''
      }
      return this.mainBannerImgList[this.slideIndex].subTitle
    },
    downloadImg() {
      return require(`@/assets/img/new_index/app_demo_black_${this.locale}.svg`);
    },
    newTheme() {
      return this.$store.state.theme;
    },
    bgImg() {
      const theme = this.newTheme == 'white' ? 'light' : 'dark'
      return require(`@/assets/img/new_index/bg_home_${theme}.png`);
    },
  },
  watch: {
    tickers: {
      handler(newValue) {
        if (newValue) {
          this.quoteList = this.getQuoteList();
          this.getBitExchange(this.indexMarketTab);
        }
      },
      immediate: true
    },
    contractList(newValue) {
      // 防止contractList取得比topFutureIdList晚
      this.top5List = this.getTop5List(this.topFutureIdList);
      this.getBitExchange(this.indexMarketTab);
    },
    indexMarketTab(newVal) {
      this.getBitExchange(newVal);
    },
    top5List(newValue) {
      // 防止top5List一開始為空的處理
      this.getBitExchange(this.indexMarketTab);
    }
  }
};
</script>
<style lang="less" scoped>
@gray-P2: rgba(112, 122, 138, 0.2); //不透明度80% 输入框背景色 输入框描边

/**for mobie use */
// @media screen and (max-width: 768px) {

  * {
    margin: 0;
    padding: 0;
  }

  html,
  body {
    width: 37.5rem;
    height: 100%;
    position: absolute;
    // left: -4px;
    // top: -4px;
    overflow: hidden;
    overflow-y: auto;
  }

  // html {
  //   font-size: getWidth750(20);
  // }

  .header_fixed {
    position: fixed;
    width: 37.5rem;
    top: 0rem;
    left: 0rem;
    z-index: 9999;
  }

  .home {
    background: @theme-black;
    width: 100%;
    margin: auto;
    padding-top: 2rem;

    .introduce {
      //background: linear-gradient(to top, @theme-black, @theme-black, rgba(248, 199, 27, 0.1));

      .introduce_cont {
        position: relative;
        width: 34.5rem;
        margin: 0 auto;

        .introduce_top {
          width: 100%;

          img {
            display: block;
            width: 100%;
          }
        }

        .introduce_bottom {
          width: 100%;

          .title {
            // margin-top: 4rem;
            //height: 3.5rem;
            font-size: 2.8rem;
            .medium-font();
            line-height: 3.2rem;
            text-align: center;
            white-space: pre-line;
          }

          .title2 {
            margin-top: 1.5rem;
            //height: 3.5rem;
            font-size: 1.7rem;
            .medium-font();
            line-height: 2.5rem;
            text-align: center;
          }

          .sub-tit {
            margin-top: 2.75rem;
            //height: 3.5rem;
            font-size: 1.4rem;
            font-weight: 400;
            line-height: 1.3rem;
            text-align: center;
          }
        }
      }
    }

    .input {
      position: relative;
      margin-top: 4.5rem;

      input {
        width: 34.5rem;
        height: 4.2rem;
        .pxToVw375H5(border-radius, 12);
        background: transparent;
        border: 1px solid @black-input-color;
        padding: 0 1.1rem 0 1.2rem;
        box-sizing: border-box;
        color: @gray-P1;
        font-weight: 500;
        font-size: 1.4rem;

        &:focus {
          border: 1px solid @yellow-P3 !important;
          background: unset;
          outline: none;
        }

        // WebKit browsers
        &::-webkit-input-placeholder {
          font-size: 1.4rem;
          font-weight: 500;
          color: @gray-P1 !important;
        }

        // Mozilla Firefox 4 to 18
        &:-moz-placeholder {
          font-size: 1.4rem;
          font-weight: 500;
          color: @gray-P1 !important;
        }

        // Mozilla Firefox 19+
        &::-moz-placeholder {
          font-size: 1.4rem;
          font-weight: 500;
          color: @gray-P1 !important;
        }

        // Internet Explorer 10+
        &:-ms-input-placeholder {
          font-size: 1.4rem;
          font-weight: 500;
          color: @gray-P1 !important;
        }
      }
    }

    .login_register_box {
      .newRegister_btn {
        background: @yellow-P1;
        color: @text-black;
      }

      .login_btn {
        background: rgba(255, 255, 255, 0.05);
      }

      .newRegister_btn,
      .login_btn {
        width: 100%;
        height: 4.2rem;
        .pxToVw375H5(border-radius, 12);
        line-height: 4.2rem;
        text-align: center;
        font-size: 1.5rem;
        margin-top: 1.5rem;
        .medium-font();
      }
    }

    .quickDownload {
      padding: 0 1.5rem;

      .title {
        height: 5.7rem;
        display: flex;

        .line {
          flex: 1;
          height: 0.05rem;
          background: rgba(112, 122, 138, 0.2);
          margin-top: 2.85rem;

        }

        .sub-tit {
          padding: 0 0.5rem;
          text-align: center;
          line-height: 5.7rem;
          font-size: 1.4rem;
          font-weight: 500;
          color: @gray-P1;
        }
      }

      .download {
        width: 34.5rem;
        display: flex;

        .btn {
          flex: 1;
          height: 4.5rem;
          background: rgba(255, 255, 255, 0.05);
          .pxToVw375H5(border-radius, 12);
          display: flex;

          .icon {
            width: 3rem;
            height: 3rem;
            margin-top: 0.8rem;
            margin-left: 1rem;
            margin-right: 1.2rem;
            ;
          }

          &:last-child {
            margin-left: 1.1rem;
          }

          >p {
            font-size: 1.4rem;
            .medium-font();
            line-height: 4.5rem;
          }
        }
      }
    }

    .market {
      margin-top: 6.15rem;
      padding: 0 1.5rem 2.5rem;

      .list_head {
        .src_org {
          display: flex;
          justify-content: space-between;

          .current_price {
            line-height: 2.25rem;
            .medium-font();
            font-size: 2.4rem;
            font-weight: 800;
          }

          .more-market-item {
            float: right;
            display: flex;
            align-items: center;
            color: @gray-P1;
            line-height: 1.3rem;
            font-size: 1.4rem;
            font-weight: 500;
            height: 1.3rem;
            margin-top: 0.5rem;

            .icon-more {

              margin-left: 0.6rem;

              img {
                width: 1.4rem;
                margin-right: unset;
              }
            }
          }
        }

        // 合约切换
        .coin-tab {
          display: flex;
          align-items: flex-center;
          align-items: center;
          //padding: 0 0 2.5rem;

          h3 {
            display: inline-block;
            margin: 3.45rem 0 0.8rem;
            font-size: 1.5rem;
            font-weight: bold;
            line-height: 1.4rem;
            color: @gray-P1;
            cursor: pointer;

            &:hover {
              color: @yellow-P3 !important;
            }

            &.active {
              border-color: @yellow-P3;
              border-width: 1.3rem;
              color: #fff;
            }
          }

          .switch {
            width: 16px;
            height: 1px;
            background: @yellow-P3;
            margin: 0 auto
          }
        }

        .list_title {
          width: 100%;
          line-height: 1.05rem;
          margin-top: 2.5rem;
          .pxToVw375H5(margin-bottom, 6);
          display: flex;
          .pxToVw375H5(font-size, 14);
          color: @gray-P1;
          justify-content: space-between;

          .price {
            .pxToVw375H5(width, 100);
          }

          .coinId {
            .pxToVw375H5(width, 120);
          }

          .change_24h {
            .pxToVw375H5(width, 100);
            text-align: right;
          }
        }
      }

      .list_content {
        .list_data {
          width: 100%;
          display: flex;
          font-size: 2.6 * 0.5rem;
          .pxToVw375H5(font-size, 16);
          justify-content: space-between;
          .pxToVw375H5Padding(18, 0);

          .list_coinId {
            .pxToVw375H5(width, 120);
            pointer-events: none;
            color: #fff;
          }

          .list_price {
            .pxToVw375H5(width, 100);
          }

          .list_change_24h {
            .pxToVw375H5(width, 100);
            text-align: right;
            color: #707a8a;

            &.price_green {
              color: @green-color;
            }

            &.price_red {
              color: @red-color;
            }
          }
        }
      }
    }

    .school {
      width: 34.5rem;
      padding: 0rem 1.5rem 0;
      .pxToVw375H5(padding-top, 48);

      .swiper {
        border-radius: 1rem;
        margin-top: unset;
      }

      video {
        width: 34.5rem;
        border-radius: 1rem;
        background: #0F1115;
      }

      .video {
        width: 34.5rem;
        border-radius: 1rem;
        background: #0F1115;
      }

      .opt {
        margin: 1.5rem 0 0rem;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
          line-height: 1.4rem;
          font-size: 1.5rem;
          font-weight: bold;
        }

        .indicator-position {
          display: flex;
          margin-right: 1rem;

          .item {
            width: 0.4rem;
            height: 0.4rem;
            background: @gray-P2;
            border-radius: 0.2rem;
            margin-left: 0.5rem;
          }

          .item.sel {
            width: 0.9rem;
            height: 0.4rem;
            border-radius: 0.2rem;
            background: @yellow-P3;
          }
        }
      }

      .school-des {
        display: flex;
        justify-content: space-between;

        .title {
          line-height: 2.25rem;
          font-size: 2.4rem;
          .bold-font();
          margin-bottom: 3.5rem;
        }

        .more-school-item {
          float: right;
          display: flex;
          align-items: center;
          color: @gray-P1;
          line-height: 1.3rem;
          font-size: 1.4rem;
          font-weight: 500;
          height: 1.3rem;
          margin-top: 0.5rem;

          .icon-more {

            margin-left: 0.6rem;

            img {
              width: 1.4rem;
              margin-right: unset;
            }
          }
        }
      }

      // }

    }

    .downloadApp {
      padding: 0 1.5rem;
      .pxToVw375H5(padding-top, 64);

      .app_download_title {
        line-height: 2.5rem;
        font-size: 2.4rem;
        .bold-font();
      }

      .app_downlaod_img {
        margin-top: 3.45rem;

        .downloadImg {
          width: 33.5rem;
          height: 22.65rem;
          margin-left: 0.5rem;
        }
      }

      .app_download_link {
        margin-top: 3rem;
        height: 29.5rem;
        text-align: center;
      }

      .qrcode {
        margin: 0 auto;
        display: flex;
        align-items: center;

        .qrcode-bg {
          margin-right: 1.55rem;
          background-position: center;
          background-size: cover;
          background-repeat: no-repeat;

          .download-qrcode-show-content {
            transition: all 0.35s;
            background: #fff;
            border-radius: 8px;
            height: 80px;

            >img {
              display: block;
              width: 100%;
              height: 100%;
            }
          }
        }

        img {
          width: 114px;
          height: 114px;
          margin-top: 5px;
        }

        p {
          font-size: 1.4rem;
          font-weight: 500;
          line-height: 1.8rem;
          text-align: left;
        }

        .title {
          height: 1.4rem;
          font-size: 1.5rem;
          font-weight: bold;
          margin-bottom: 1.5rem;
        }
      }

      .down_app {
        margin-top: 4rem;

        .app-item {
          cursor: pointer;
          width: 16.7rem;
          height: 5rem;
          background: rgba(255, 255, 255, 0.05);
          .pxToVw375H5(border-radius, 12);
          display: flex;

          .icon {
            width: 3rem;
            height: 3rem;
            margin-top: 1rem;
            margin-left: 1rem;
            margin-right: 1.2rem;
            background-size: contain;
            background-repeat: no-repeat;
          }

          p {
            font-size: 1.4rem;
            .medium-font();
            line-height: 5rem;
          }
        }

        .icon_top {
          display: flex;

          .ios_app {
            margin-right: 1.1rem;

            .icon {
              background-image: url(~assets/img/new_index/icon_ios.png);
            }
          }

          .android_app {
            .icon {
              background-image: url(~assets/img/new_index/icon_android.png);
            }
          }
        }

        .icon_bottom {
          display: flex;
          margin-top: 1rem;
          text-align: center;

          .ios_app2 {
            margin-right: 1.1rem;

            .icon {
              background-image: url(~assets/img/new_index/icon_appstore.png);
            }
          }

          .android_app2 {

            .icon {
              background-image: url(~assets/img/new_index/icon_Google-Play.png);
            }
          }
        }
      }
    }
  }

  .email-error {
    color: rgb(236, 69, 81);
  }

  .weexcs {
    position: fixed;
    bottom: 20 * 0.5rem;
    right: 1 * 0.5rem;
    justify-content: center;
    align-items: center;
    display: block;
    z-index: 99;
  }

  .weexcs img {
    width: 12 * 0.5rem;
  }

  .twitter img {
    width: 12 * 0.5rem;
  }

  .twitter {
    position: fixed;
    bottom: 20 * 0.5rem;
    right: 1 * 0.5rem;
    justify-content: center;
    align-items: center;
    display: block;
    z-index: 99;
  }

  .wrap {
    width: 100%;
    margin: 0 auto;
  }

  .anchor {
    height: 12.8 * 0.5rem;
    background-color: #eeeeee;
    display: flex;
    // border: #19a66c 1px solid;
  }

  .close {
    width: 2.4 * 0.5rem;
    height: 2.4 * 0.5rem;
    margin-top: 5.2 * 0.5rem;
    position: absolute;
    right: 3.9 * 0.5rem;
  }

  .anchorLogo {
    width: 7.6 * 0.5rem;
    height: 7.6 * 0.5rem;
    margin-top: 2.6 * 0.5rem;
    margin-left: 3.2 * 0.5rem;
  }

  .downloadBtn {
    width: 13.6 * 0.5rem;
    height: 7.6 * 0.5rem;
    background: #f4b800;
    font-size: 1.4rem;
    border-radius: 1.2 * 0.5rem;
    color: #333333;
    text-align: center;
    position: absolute;
    font-weight: 500;
    right: 9.2 * 0.5rem;
    margin-top: 2.6 * 0.5rem;
    line-height: 7.5 * 0.5rem;
  }

  .downloadBtn_en {
    width: 22.4 * 0.5rem;
    .medium-font();
  }

  .anchorW {
    margin-top: 2.3 * 0.5rem;
  }

  .anchorWord1 {
    height: 3.4 * 0.5rem;
    line-height: 3.4 * 0.5rem;
    // font-family: Helvetica-Bold;
    font-size: 3.4 * 0.5rem;
    color: #333333;
    letter-spacing: 0;
    margin-left: 2.1 * 0.5rem;
    margin-top: 0.8 * 0.5rem;
    font-weight: bold;
  }

  .anchorWord2 {
    height: 3.3 * 0.5rem;
    line-height: 3.3 * 0.5rem;
    // font-family: PingFangSC-Regular;
    font-size: 2.8 * 0.5rem;
    color: #333333;
    letter-spacing: 0.077 * 0.5rem;
    margin-top: 0.4 * 0.5rem;
    margin-left: 2.1 * 0.5rem;
    font-weight: 500;
  }



  .swiper {
    width: 34.5rem;
    margin-top: 5rem;
    height: 17.5rem;
  }

  .pic {
    border: 1px solid yellow;
    height: 17.8 * 0.5rem;
  }

  .w1 {
    height: 4 * 0.5rem;
    width: 18.1 * 0.5rem;
    margin-left: 3.3 * 0.5rem;
  }

  .w12 {
    /* color: #fff; */
    height: 4 * 0.5rem;
    line-height: 4 * 0.5rem;
    width: 100%;
    margin-left: 3.3 * 0.5rem;
  }

  .w2 {
    color: #fff;
    width: 100%;
    // border: 1px solid rgb(255, 238, 0);
    margin-top: 1.9 * 0.5rem;
    // font-family: PingFangSC-Regular;
    font-size: 3.6 * 0.5rem;
    color: #ffffff;
    letter-spacing: 0.114 * 0.5rem;
    line-height: 5 * 0.5rem;
  }

  .w21_h5 {
    width: 68.6 * 0.5rem;
    color: #fff;
    height: 5.8 * 0.5rem;
    line-height: 5 * 0.5rem;
    margin-left: 3.2 * 0.5rem;
    font-size: 3.2 * 0.5rem;
    margin-top: 2.8 * 0.5rem;
    // font-family: PingFangSC Regular, PingFang SC;
    font-weight: 300;
    color: #eaecef;
  }

  .w22_h5 {
    // width: 25.2 * 0.5rem;
    color: #fff;
    // height: 5 * 0.5rem;
    // line-height: 5 * 0.5rem;
    margin-left: 3.2 * 0.5rem;
    font-size: 4.8 * 0.5rem;
    // font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 600;
    color: #eaecef;
  }

  .w21_pc {
    display: none;
  }

  .lang_menu {
    display: flex;
    // border: 1px solid red;
    margin-right: 3.2 * 0.5rem;
    margin-top: 3.3 * 0.5rem;
    float: right;
  }

  .langCN {
    width: 4.4 * 0.5rem;
    height: 4.4 * 0.5rem;
    border-radius: 4.4 * 0.5rem;
    line-height: 4.4 * 0.5rem;
    // font-family: PingFangSC-Medium;
    font-size: 2.4 * 0.5rem;
    text-align: center;
    background-color: #fff;
    // color: #cccccc;
  }

  .lang_active {
    background-color: #fff;
    color: #1b1f26 !important;
  }

  .langEN {
    width: 4.4 * 0.5rem;
    height: 4.4 * 0.5rem;
    border-radius: 4.4 * 0.5rem;
    line-height: 4.4 * 0.5rem;
    // font-family: Arial;
    font-size: 2.4 * 0.5rem;
    text-align: center;
    margin-right: 1 * 0.5rem;
    // color: #cccccc;
  }

  .lang_none_active {
    background-color: unset;
    color: #cccccc;
  }

  .logo img {
    width: 13.7 * 0.5rem;
    height: 3.3 * 0.5rem;
    margin-top: 3.9 * 0.5rem;
    margin-left: 3.2 * 0.5rem;
  }

  .data_pc {
    display: none;
  }

  .more_data {
    margin: 2rem auto;
    text-align: center;
    color: #9ca3ae;
    font-size: 1.3rem;
    cursor: pointer;
  }

  .list_line {
    width: 68.6 * 0.5rem;
    margin: 0 auto;
    height: 0px;
    border: 0.5px solid #e9ebed;
  }





  .current_price_org {
    opacity: 0.6;
    // font-family: PingFangSC-Regular;
    font-size: 2.2 * 0.5rem;
    color: #707a8a;
    letter-spacing: 0.7px;
    padding-top: 4.2 * 0.5rem;
  }

  .newRegister_btn_en {
    margin-top: 37 * 0.5rem;
    .medium-font();
  }

  .price_gray {
    color: gray;
  }

  .list_img {
    width: 3 * 0.5rem;
    height: 3 * 0.5rem;
    margin-left: 3.3 * 0.5rem;
    margin-top: 3.5 * 0.5rem;
    margin-bottom: 3.4 * 0.5rem;
  }




  // .app_download_describe {
  //   text-align: center;
  //   font-weight: bold;
  //   font-size: 2.4 * 0.5rem;
  //   // font-family: MicrosoftYaHei;
  //   letter-spacing: 0.6 * 0.5rem;
  //   color: #707a8a;
  //   padding-top: 1.2 * 0.5rem;
  // }
  .app_download_imgblock {
    margin-top: 6.8 * 0.5rem;
    width: 75 * 0.5rem;
    display: flex;
  }


  .app_footer {
    width: 66.6 * 0.5rem;
    height: 10 * 0.5rem;
    margin: 0 auto;
    border-top: 1px #d8d8d8 solid;
  }

  .why {
    width: 100%;
    margin-bottom: 13.4 * 0.5rem;
    // border: 1px solid green;
  }

  .why_weex {
    width: 37.1 * 0.5rem;
    margin: 7 * 0.5rem auto 10 * 0.5rem;
    // border: 1px solid green;
    // font-family: PingFangSC-Semibold;
    font-size: 4.6 * 0.5rem;
    color: #000000;
    letter-spacing: 0;
    text-align: center;
    font-weight: bold;
  }

  .why1 {
    margin-top: 6 * 0.5rem;
    text-align: center;
    // border: 1px solid green;
  }

  .why_img1 {
    margin: 0 auto;
    width: 9.6 * 0.5rem;
    height: 9.6 * 0.5rem;
  }

  .why_title1 {
    width: 100%;
    height: 4.5 * 0.5rem;
    // font-family: PingFangSC-Semibold;
    font-size: 3.2 * 0.5rem;
    font-weight: bold;
    color: #1e2329;
    letter-spacing: 0;
    margin: 0 auto;
    margin-top: 4 * 0.5rem;
    // border: 1px solid blue;
  }

  .why_content1 {
    width: 65.4 * 0.5rem;
    // font-family: PingFangSC-Semibold;
    font-size: 2.8 * 0.5rem;
    color: #474d57;
    letter-spacing: 0;
    margin: 0 auto;
    margin-top: 2.8 * 0.5rem;
  }

  .hide {
    display: none;
  }

  .notice {
    display: flex;
    padding: 0 1.5rem;
    //justify-content: center;
    //margin: 0.8 * 0.5rem 0 1.6 * 0.5rem;
    align-items: center;
    font-size: 2.6 * 0.5rem;

    img {
      width: 2.5rem;
      margin-right: 1.05rem;
    }

    .time {
      margin: 0 1.6 * 0.5rem;
      display: none;
    }

    .more {
      padding-left: 1.05rem;
      cursor: pointer;

      img {
        width: 1.4rem;
        margin-right: unset;
      }
    }

    .marquee_box {
      display: block;
      position: relative;
      width: 28.5rem;
      height: 3 * 0.5rem;
      overflow: hidden;
    }

    .marquee_list {
      display: block;
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
    }

    .marquee_top {
      transition: all 0.5s;
      margin-top: -3 * 0.5rem;
    }

    .marquee_list li {
      height: 3 * 0.5rem;
      line-height: 3 * 0.5rem;
      font-size: 2.6 * 0.5rem;
      //padding: 0 16px 0 0;
      display: flex;
      justify-content: space-between;

      .title {
        //width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        color: #fff;
      }
    }
  }

  .contact_us {
    width: 75 * 0.5rem;
    // height: 71.5 * 0.5rem;
    background: #fafafa;
    padding-bottom: 8 * 0.5rem;
    // border: 1px solid fafafa;
    display: inline-block;

    .title {
      width: 25 * 0.5rem;
      height: 6.5 * 0.5rem;
      font-size: 4.6 * 0.5rem;
      // font-family: MicrosoftYaHei-Bold, MicrosoftYaHei;
      font-weight: bold;
      color: #333333;
      line-height: 6.5 * 0.5rem;
      margin: 8 * 0.5rem auto 6 * 0.5rem;
    }

    .c1 {
      width: 100%;
      height: 17 * 0.5rem;
      margin-bottom: 0rem;

      .block {
        width: 54 * 0.5rem;
        margin-left: 3.2 * 0.5rem;
        display: flex;

        .img {
          width: 6 * 0.5rem;
          height: 6 * 0.5rem;
        }

        .text {
          width: 47 * 0.5rem;
          height: 6 * 0.5rem;
          font-size: 2.8 * 0.5rem;
          // font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 6 * 0.5rem;
          margin-left: 4 * 0.5rem;
        }
      }

      .block2 {
        width: 54 * 0.5rem;
        margin-top: 5 * 0.5rem;
        margin-left: 3.2 * 0.5rem;
        display: flex;

        .img {
          width: 6 * 0.5rem;
          height: 6 * 0.5rem;
        }

        .text2 {
          width: 47 * 0.5rem;
          height: 6 * 0.5rem;
          font-size: 2.8 * 0.5rem;
          // font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 6 * 0.5rem;
          margin-left: 4 * 0.5rem;
        }
      }
    }

    .c2 {
      width: 100%;
      height: 17 * 0.5rem;

      margin-top: 3 * 0.5rem;

      .block {
        width: 54 * 0.5rem;
        margin-top: 5 * 0.5rem;
        margin-left: 3.2 * 0.5rem;
        display: flex;

        .img {
          width: 6 * 0.5rem;
          height: 6 * 0.5rem;
        }

        .text {
          width: 47 * 0.5rem;
          height: 6 * 0.5rem;
          font-size: 2.8 * 0.5rem;
          // font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 6 * 0.5rem;
          margin-left: 4 * 0.5rem;
        }
      }

      .block2 {
        width: 54 * 0.5rem;
        display: flex;
        margin-top: 5 * 0.5rem;
        margin-left: 3.2 * 0.5rem;

        // border: 1px solid darkgreen;
        .img {
          width: 6 * 0.5rem;
          height: 6 * 0.5rem;
        }

        .text2 {
          width: 60 * 0.5rem;
          height: 6 * 0.5rem;
          font-size: 2.8 * 0.5rem;
          // font-family: MicrosoftYaHei;
          color: #333333;
          line-height: 6 * 0.5rem;
          margin-left: 4 * 0.5rem;
        }
      }
    }
  }

  .officia-wrap {
    .pxToVw375H5Padding(50, 0);
    background: @black-module-color;

    .officia-img {
      width: 100%;
      .pxToVw375H5(height, 208);
      background-position: center;
      background-repeat: no-repeat;
      background-size: contain;
      background-image: url(~assets/img/new_index/img_weex_data_black.png);
    }

    .officia-item {
      display: block;
      text-align: center;
      .pxToVw375H5(margin-bottom, 8);

      .title {
        .bold-font();
        .pxToVw375H5(font-size, 32);
        color: #fff;
      }

      .txt {
        .pxToVw375H5(margin-top, 8);
        .pxToVw375H5(font-size, 14);
        color: @black-text-secondary;
      }

      .right-arrow-wrap {
        padding-right: 28px;
        display: inline-block;
        height: 24px;
        line-height: 24px;
        background-image: url('~assets/img/common/right-arrow-white.png');
        background-size: 16px 16px;
        background-position: right center;
        background-repeat: no-repeat;
      }
    }
  }

  .joint-venture {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .pxToVw375H5Padding(0, 16);
    .pxToVw375H5(padding-bottom, 100);
    .pxToVw375H5(row-gap, 8);

    .joint-item {
      .pxToVw375H5(width, 90);
      .pxToVw375H5(height, 38);
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }
  }

  .home {
    color: @text-white;

    &.home_white {
      background: @theme-white;
      color: #000;

      .login_register_box .login_btn,
      .quickDownload .download .btn,
      .downloadApp .down_app .app-item {
        background: rgba(0, 0, 0, 0.05);
      }

      .market .list_head .coin-tab span.active {
        color: #000;
      }

      .officia-wrap {
        background: @white-module-color;

        .officia-item .title {
          color: #000;
        }

        .officia-item .right-arrow-wrap {
          background-image: url('~assets/img/common/right-arrow-black.png');
        }

        .officia-img {
          background-image: url(~assets/img/new_index/img_weex_data_white.png);
        }

        .officia-item .txt {
          color: @white-text-secondary;
        }
      }

      .input input {
        border: 1px solid @white-input-color;
      }
    }
  }
// }
</style>
