import { render, staticRenderFns } from "./DateCountdown.vue?vue&type=template&id=394c38a6&scoped=true"
import script from "./DateCountdown.vue?vue&type=script&lang=js"
export * from "./DateCountdown.vue?vue&type=script&lang=js"
import style0 from "./DateCountdown.vue?vue&type=style&index=0&id=394c38a6&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "394c38a6",
  null
  
)

export default component.exports