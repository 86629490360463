<template>
  <div class="date-countdown">
    <section
      class="date-countdown-label"
      :class="{'date-countdown-label-black':isBlack}"
    >{{$t("ad_dialog.countdown")}}</section>
    <section class="date-countdown-value">
      <number-card :text="day" />
      <word-card :text="$t('cnns.d')" />
      <number-card :text="hour" />
      <word-card :text="$t('cnns.h')" />
      <number-card :text="minutes" />
      <word-card :text="$t('cnns.m')" />
      <number-card :text="second" />
      <word-card :text="$t('cnns.s')" />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import NumberCard from './NumberCard.vue';
import WordCard from './WordCard.vue';

export default {
  components: {
    NumberCard,
    WordCard
  },
  props: ['currentTime', 'countdownTime'],
  data() {
    return {
      timerid: 0,
      day: 0,
      hour: 0,
      minutes: 0,
      second: 0,
      currentTimeStore: this.currentTime
    };
  },
  computed: {
    ...mapState(['newTheme']),
    isBlack() {
      return this.newTheme === 'black';
    }
  },
  watch: {
    countdownTime: function (newVal) {
      // this.timerid && clearTimeout(this.timerid);
      if (newVal) {
        this.calcTime();
      }
    },
    currentTime:function(){
      if (this.countdownTime) {
        this.calcTime();
      }
    }
  },
  methods: {
    calcTime() {
      if (this.countdownTime) {
        const now = this.currentTimeStore || new Date().getTime();
        const end = new Date(this.countdownTime).getTime();
        const t = end - now;
        if (t <= 0) {
          return;
        }
        this.day = Math.floor(t / (1000 * 60 * 60 * 24));
        this.hour = Math.floor((t % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
        this.minutes = Math.floor((t % (1000 * 60 * 60)) / (1000 * 60));
        this.second = Math.floor((t % (1000 * 60)) / 1000);
        this.currentTimeStore += 1000;
        this.countdown();
      }
    },
    countdown() {
      this.timerid && clearTimeout(this.timerid);
      this.timerid = setTimeout(() => {
        this.calcTime();
      }, 1000);
    }
  },
  mounted() {
    this.calcTime();
  }
};
</script>

<style lang="less" scoped>
.date-countdown {
  display: flex;
  align-items: center;
  margin-top: 10px;
  .date-countdown-label {
    font-size: 14px;
    font-weight: 400;
    color: @always-black;
    margin-right: 16px;
  }
  .date-countdown-label-black {
    color: @always-white;
  }
  .date-countdown-value {
    display: flex;
  }
}
</style>