<template>
    <div>
        <div v-swiper:mySwiper="swiperOptions" ref="mySwiper" class="videosSwiper" :class="newTheme">
            <div class="swiper-wrapper">
                <div v-for="(item, i) in videoList" :key="i" class="swiper-slide">
                    <video :id="'video0-' + i" :src="item.url" controls="" preload="auto" playsinline="true"
                        :poster="item.poster" controlsList="nodownload" @play="onplay('video0-' + i)" />
                </div>
            </div>
            <div class="swiper-bottom">
                <div>{{ txtList[vNum] }}?</div>
                <div class="pagination-wrap">
                </div>
            </div>
        </div>

    </div>
</template>

<script>
import { mapState } from 'vuex';
export default {
    data() {
        const _this = this;
        return {
            vNum: 0,
            txtList: [
                this.$t("school.tabs_info.tab1_info1"),
                this.$t("school.tabs_info.tab1_info2"),
                this.$t("school.tabs_info.tab6")
            ],
            videoList: [
                {
                    url: "https://media.weex.com/xuetang/video1.mp4",
                    poster: "/assets/images/new_index/v1.png",
                    playing: false
                },
                {
                    url: "https://media.weex.com/xuetang/video2.mp4",
                    poster: "/assets/images/new_index/v2.png",
                    playing: false
                },
                {
                    url: "https://media.weex.com/xuetang/video20.mp4",
                    poster: "/assets/images/new_index/v4.png",
                    playing: false
                }
            ],
            swiperOptions: {
                pagination: {
                    el: '.pagination-wrap',
                    clickable: true
                },
                on: {
                    slideChange() {
                        _this.vNum = this.activeIndex;
                    },
                }
            }
        }
    },
    methods: {
        onplay(id) {
            this.pauseOtherVideo(id);
        },
        switchBanner(index) {
            // this.vNum = index;
        },
        pauseOtherVideo(id) {
            let allVideoElement = document.getElementsByTagName('video') // 获取页面上所有的video对象
            if (allVideoElement && allVideoElement.length > 0) {
                for (let i = 0; i < allVideoElement.length; i++) {
                    if (allVideoElement[i].id != id) allVideoElement[i].pause()
                }
            }
        },
    },
    computed: {
        ...mapState(['newTheme']),
        swiper() {
            return this.$refs.mySwiper;
        },
    },
}
</script>

<style lang="less" scoped>
::v-deep .white {
    &.videosSwiper {
        color: #000;
    }

    .swiper-bottom .pagination-wrap .swiper-pagination-bullet {
        background: @white-sliderdot-color;

        &.swiper-pagination-bullet-active {
            background: #000;
        }
    }
}

.videosSwiper {
    position: relative;
    color: #fff;
    overflow: hidden;

    .swiper-slide {
        width: 100% !important;
    }
}

::v-deep .swiper-bottom {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 16px;

    .pagination-wrap {
        display: flex;
        gap: 8px;

        .swiper-pagination-bullet {
            display: block;
            width: 6px;
            height: 6px;
            border-radius: 6px;
            background: @black-sliderdot-color;

            &.swiper-pagination-bullet-active {
                width: 15px;
                background: #fff;
            }
        }
    }

    .swiper-pagination {
        display: none;
        width: auto;
        position: inherit;
    }
}

.swiper {
    height: 20rem;
    border-radius: 1rem;
}

video {
    width: 100%;
    //height: 20rem;
    border-radius: 1rem;
    background: @black-bg-primary;
}

.video {
    width: 100%;
    //height: 20rem;
    border-radius: 1rem;
    background: @black-bg-primary;
}

.swiper-button-next,
.swiper-button-prev {
    color: white !important;
    background: rgba(112, 122, 138, 0.5);
    padding: 0.6rem 0.3rem;
    top: calc(50% - 1rem)
}

.swiper-button-next {
    right: 0;
    border-bottom-left-radius: 0.6rem;
    border-top-left-radius: 0.6rem;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    font-size: 2rem;
}

.swiper-button-prev {
    left: 0;
    border-bottom-right-radius: 0.6rem;
    border-top-right-radius: 0.6rem;
}
</style>