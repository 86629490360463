<template>
  <div class="world-card" :class="{'world-card-black':isBlack}">{{text}}</div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: ['text'],
  computed: {
    ...mapState(['newTheme']),
    isBlack() {
      return this.newTheme === 'black';
    }
  }
};
</script>

<style lang="less" scoped>
.world-card {
  height: 38px;
  .medium-font();
  font-size: 10.89px;
  font-weight: 500;
  line-height: 38px;
  text-align: center;
  color: @always-black;
  margin: 0 5.45px;
}
.world-card-black {
  color: @always-white;
}
</style>
