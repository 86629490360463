<template>
  <div class="home-swiper-box-h5">
    <div v-swiper:mySwiper="swiperOption" @click-slide="clickSlide" class="swiperWrap">
      <div class="swiper-wrapper">
        <div v-for="(item, index) in bannerImg" :key="index" class="swiper-slide">
          <a :href="bannerLink[index]" style="pointer-events: none;display: block;" >
            <img :src="bannerImg[index]" alt/>
          </a>
        </div>
      </div>
      <div class="swiper-pagination swiper-pagination-bullets" style="text-align: center; bottom: 0"></div>
    </div>
  </div>
</template>

<script>
import {  mapState } from 'vuex';
import { getAnalytics, logEvent } from "firebase/analytics";
import { firebaseApp } from '~/plugins/firebase.js';
// Initialize Firebase
// const analytics = getAnalytics(firebaseApp);
export default {
  data() {
    const _this = this;
    return {
      swiperOption: {
        loop: this.bannerImg.length > 1,
        slidesPerView: "auto",
        // centeredSlides: true,
        spaceBetween: "5%",
        // scrollbarHide: true,
        autoplay: {
          delay: 3000, // 圖片停留時間3秒
          stopOnLastSlide: false,
          disableOnInteraction: false
        },
        speed: 500, //輪播速度
        pagination: {
          el: ".swiper-pagination",
          type: "custom",
          renderCustom: function (swiper, current, total) {
            // 这里因为 this 作用域的关系，不能直接使用 this 获取 Vue 相关内容，通过上面的 const vm = this，使用 vm 获取
            let _html = "";
            const activeColor = _this.newTheme =='white'?'#000':'#fff'
            const defaultColor = _this.newTheme =='white'?'#ADAFB8':'#4D4F5A'
            for (let i = 1; i <= total; i++) {
              if (current == i) {
                _html += `<div style="width:0.9rem;height:0.4rem;border-radius:0.2rem;background-color:${activeColor};margin-left:0.4rem;margin-right:0.4rem"></div>`;
              } else {
                _html += `<div style="width:0.4rem;height:0.4rem;border-radius:0.4rem;background-color:${defaultColor};margin-left:0.4rem;margin-right:0.4rem"></div>`;
              }
            }
            return (
                '<div style="justify-content:center;width:100%;align-items:center;display:flex;">' +
                _html +
                "</div>"
            );
          }
        },
        on: {
          slideChange() {
            //console.log("onSlideChangeEnd", this);
          },
          click(e) {
            let num = 0;
            if (_this.bannerLink && _this.bannerLink.length > 0) {
              num = this.clickedIndex % _this.bannerLink.length;
              //_this.openLink(_this.bannerLink[num]);
              if (_this.bannerLink[num].indexOf('topic/agent-introduction-topic') != -1) {
                _this.goto('topic/agent-introduction-topic', _this.bannerTitle[num]);
              } else if (_this.bannerLink[num].indexOf('competition') != -1) {
                _this.goto('competition', _this.bannerTitle[num]);
              } else {
                _this.openLink(_this.bannerLink[num], _this.bannerTitle[num]);
              }
            }
          }
        }
      },
      analytics : {}
    };
  },
  props: {
    bannerImg: {
      type: Array,
      default: () => []
    },
    bannerLink: {
      type: Array,
      default: () => []
    },
    bannerTitle: {
      type: Array,
      default: () => []
    },
  },
  computed: {
    ...mapState(['newTheme']),
  },
  mounted() {
    this.analytics = getAnalytics(firebaseApp);
  },
 
  methods: {
    /**
     * 点击banner业务处理
     * @param index
     * @param reallyIndex 下标数
     */
    clickSlide(index, reallyIndex) {
      let _this = this;
      this.bannerLink.forEach(function (item, index, array) {
        if (reallyIndex == index && item != "") {
          if (_this.bannerLink[index].indexOf('topic/agent-introduction-topic') != -1) {
            _this.goto('topic/agent-introduction-topic', _this.bannerTitle[index]);
          } else if (_this.bannerLink[index].indexOf('competition?fromAPP=Y') != -1) {
            _this.goto('competition?fromAPP=Y', _this.bannerTitle[index]);
          } else if (_this.bannerLink[index].indexOf('competition') != -1) {
            _this.goto('competition', _this.bannerTitle[index]);
          } else {
            _this.openLink(_this.bannerLink[index], _this.bannerTitle[index]);
          }
        }
      });
    },
    goto(path, title) {
      //logEvent(this.analytics, '轮播图-'+title);
      logEvent(this.analytics, 'banner_event', {
        content_type: title,
        platform: "h5"
      });
      this.$router.push(this.$i18n.path(path));
    },
    openLink(hrefStr, title) {
      //logEvent(this.analytics, '轮播图-'+title);
      logEvent(this.analytics, 'banner_event', {
        content_type: title,
        platform: "h5"
      });
      window.open(hrefStr);
    }
  }
};
</script>
<style lang="less" scoped>
/**for mobie use */
//  @media screen and (max-width: 768px) {
  // @function getWidth750($width) {
  //   @return $width * calc(100vw / 750);
  // }

  * {
    margin: 0;
    padding: 0;
  }

  html,
  body {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    overflow: hidden;
    overflow-y: auto;
  }

  // html {
  //   font-size: getWidth750(20);
  // }

  .home-swiper-box-h5 {
    .swiperWrap {
      margin-left: 1.5rem;
      padding-bottom:15px;
      // border: 1px solid red;
      .swiper-slide {
        width: 25.9rem;
        // border: 2px solid green;
        cursor: pointer;
        img {
          width: 25.9rem;
          height: 13.5rem;
          border-radius: 0.6rem;
        }
      }
    }
  }
// }
</style>