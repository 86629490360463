<template>
  <div v-swiper:mySwiper="{...swiperOption, loop: bannerImg.length > 4}" v-if="bannerImg.length" class="swiperWrap">
    <div class="swiper-wrapper" style="display: flex">
      <div v-for="(item, index) in bannerImg"  :key="index" class="swiper-slide">
        <a :href="bannerLink[index]" style="pointer-events: none;display: block;">
          <img :src="item" :key="item" alt="" />
        </a>
      </div>
    </div>
    <div class="left-btn opt" v-if="bannerImg.length > 4" @click.stop="slide">
      <div class="btn"></div>
    </div>
    <div class="right-btn opt" v-if="bannerImg.length > 4" @click.stop="slide">
      <div class="btn"></div>
    </div>
  </div>
</template>

<script>
let _this = this;

import { getAnalytics, logEvent } from 'firebase/analytics';
import { firebaseApp } from '~/plugins/firebase.js';
// Initialize Firebase
// const analytics = getAnalytics(firebaseApp);
export default {
  data() {
    return {
      swiperOption: {
        loop: true,
        slidesPerView: 'auto',
        spaceBetween: '1.3%',
        // scrollbarHide: true,
        autoplay: {
          delay: 4000, // 圖片停留時間4秒
          stopOnLastSlide: false,
          disableOnInteraction: true
        },
        navigation: {
          nextEl: '.right-btn',
          prevEl: '.left-btn'
        },
        speed: 500, //輪播速度
        pagination: {
          el: '.swiper-pagination',
          type: 'custom',
          clickable: true,
          renderCustom: function(swiper, current, total) {
            // 这里因为 this 作用域的关系，不能直接使用 this 获取 Vue 相关内容，通过上面的 const vm = this，使用 vm 获取
            let _html = '';
            for (let i = 1; i <= total; i++) {
              if (current == i) {
                _html += `<div style="width:40px;height:6px;background-color:#FFE82D;margin-left:8px;margin-right:8px"></div>`;
              } else {
                _html += `<div style="width:40px;height:6px;background-color:rgba(255,255,255,0.20);margin-left:8px;margin-right:8px"></div>`;
              }
            }
            return (
              '<div style="justify-content:center;width:100%;height:70px;align-items:center;display:flex;">' +
              _html +
              '</div>'
            );
          }
        },
        on: {
          slideChange() {
            //console.log("onSlideChangeEnd", this);
          },
          tap() {
            //console.log("onTap", this);
          },
          click(e) {
            if (!_this.isSlide) {
              let num = 0;
              if (_this.bannerLink && _this.bannerLink.length > 0) {
                num = this.clickedIndex % _this.bannerLink.length;
                //_this.openLink(_this.bannerLink[num]);
                if (_this.bannerLink[num].indexOf('topic/agent-introduction-topic') != -1) {
                  _this.goto('topic/agent-introduction-topic', _this.bannerTitle[num]);
                } else if (_this.bannerLink[num].indexOf('competition') != -1) {
                  _this.goto('competition', _this.bannerTitle[num]);
                } else {
                  _this.openLink(_this.bannerLink[num], _this.bannerTitle[num]);
                }
              }
            } else _this.isSlide = false;
          }
        }
      },
      isSlide: false,
      analytics: {}
    };
  },
  props: {
    bannerImg: {
      type: Array,
      default: () => []
    },
    bannerLink: {
      type: Array,
      default: () => []
    },
    bannerTitle: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    _this = this;
    // Initialize Firebase
    this.analytics = getAnalytics(firebaseApp);
  },
  methods: {
    /**
     * 点击banner业务处理
     * @param index
     * @param reallyIndex 下标数
     */
    clickSlide(index, reallyIndex) {
      let _this = this;
      this.bannerLink.forEach(function(item, index, array) {
        if (reallyIndex == index && item != '') {
          _this.openLink(_this.bannerLink[index], _this.bannerTitle[index]);
        }
      });
    },
    goto(path, title) {
      logEvent(this.analytics, 'banner_event', {
        content_type: title,
        platform: 'web'
      });
      this.$router.push(this.$i18n.path(path));
    },
    openLink(hrefStr, title) {
      // const analytics = getAnalytics(firebaseApp);
      //this.$matomo.trackEvent('轮播图点击', `标题：${title}`)
      logEvent(this.analytics, 'banner_event', {
        content_type: title,
        platform: 'web'
      });
      window.open(hrefStr);
    },
    slide(event) {
      this.isSlide = true;
    }
  }
};
</script>
<style lang="less" scoped>
* {
  margin: 0;
  padding: 0;
}

html,
body {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  overflow: hidden;
  overflow-y: auto;
}

html {
  font-size: getWidth1920(20);
}

.swiperWrap {
  position: relative;
  //margin-left: 3.2 * 0.5rem;
  // border: 1px solid red;
  .swiper-slide {
    width: 288px;
    height: 150px;
    cursor: pointer;
    // border: 2px solid green;
    div {
      line-height: 0;
    }
    img {
      width: 288px;
      height: 150px;
      border-radius: 16px;
      cursor: pointer;
      // &:hover {
      //   box-sizing: border-box;
      //   border: 0.05rem solid @yellow-P3;
      //   border-radius: 0.4rem;
      // }
    }
  }

  .opt {
    position: absolute;
    top: 0;
    height: 150px;
    width: 48px;
    z-index: 999;

    .btn {
      width: 32px;
      margin: 56px 8px;
      height: 32px;
    }
  }

  .left-btn {
    left: 0;

    .btn {
      background: url('~assets/img/banner/icon_left.svg') no-repeat;
      background-size: 100% 100%;
    }
  }

  .right-btn {
    right: 0;

    .btn {
      background: url('~assets/img/banner/icon_right.svg') no-repeat;
      background-size: 100% 100%;
    }
  }

  .left-btn:hover {
    // left: 0;
    // border-top-left-radius: 0.6rem;
    // border-bottom-left-radius: 0.6rem;
    // background: rgba(0, 0, 0, 0.5);

    .btn {
      background: url('~assets/img/banner/icon_left_h.svg') no-repeat;
      background-size: 100% 100%;
    }
  }

  .right-btn:hover {
    // right: 0;
    // border-top-right-radius: 0.6rem;
    // border-bottom-right-radius: 0.6rem;
    // background: rgba(0, 0, 0, 0.5);

    .btn {
      background: url('~assets/img/banner/icon_right_h.svg') no-repeat;
      background-size: 100% 100%;
    }
  }
}
</style>
