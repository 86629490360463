<template>
  <div
    class="wx-dialog-container"
    :class="{'wx-dialog-show':visible,'black-wx-dialog':theme==='black'}"
  >
    <section class="wx-dialog-body">
      <slot></slot>
    </section>
  </div>
</template>
  
<script>
import { mapState } from 'vuex';

export default {
  name: 'wx-dialog',
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    bodyClass: {
      type: String,
      default: ''
    }
  },

  computed: {
    ...mapState({
      theme: state => state.theme
    })
  }
};
</script>
  
<style lang="less" scoped>
// @note 自定义弹窗
.wx-dialog-container {
  z-index: 9999999;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: none;
  justify-content: center;
  align-items: center;
  background-color: rgba(23, 23, 29, 0.5);
  .wx-dialog-body {
    // width: 700px;
    min-height: 400px;
    border-radius: 24px;
    padding: 12px;
  }
}
.black-wx-dialog {
  background-color: rgba(0, 0, 0, 0.7);
}

.wx-dialog-show {
  display: flex;
}
</style>