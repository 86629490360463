<template>
  <div class="popular-activity-block" v-if="isShow">
    <h2 class="popular-title">{{ $t('index.popular_activity') }}</h2>
    <section class="item-row">
      <activity-card v-for="item in activityItems" v-bind="item" :key="item.id" :isLittle="isLittle" />
    </section>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import ActivityCard from './ActivityCard.vue';
import { getPopularActivity } from '~/server/common/index.js';

export default {
  components: {
    'activity-card': ActivityCard
  },
  data() {
    return {
      activityItems: []
    };
  },
  computed: {
    ...mapState(['locale']),
    isShow() {
      const len = this.activityItems.length;
      return len === 2 || len === 3;
    },
    isLittle() {
      return this.activityItems.length === 3;
    }
  },
  watch: {
    locale(val) {
      this.getData(val);
    }
  },
  methods: {
    getData(locale) {
      let lang = locale;
      switch (locale) {
        case 'zh-CN':
          lang = 'cn';
          break;
        case 'zh-TW':
          lang = 'tw';
          break;
      }
      getPopularActivity({ lang }).then(res => {
        this.activityItems = [];
        if (res.code === '00000') {
          this.activityItems = res.data || [];
        }
      });
    }
  },
  mounted() {
    this.getData(this.locale);
  }
};
</script>

<style lang="less" scoped>
div,
section {
  box-sizing: border-box;
}
.popular-activity-block {
  box-sizing: border-box;
  width: 1200px;
  margin: auto;
  margin-bottom: 64px;
  .popular-title {
    font-family: @bold-font;
    font-size: 28px;
    font-weight: 700;
    color: #fff;
    margin-bottom: 24px;
  }
  .item-row {
    display: flex;
  }
}
</style>